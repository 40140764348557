<template>
    <nav-tabs :tabs="routes" />
    <router-view></router-view>
</template>

<script>
import NavTabs from "../../components/layouts/NavTabs.vue";

export default {
    components: { NavTabs },
    data() {
        return {
            routes: [
                { label: "Ranking", route: "ranking.ranking" },
                { label: "Níveis", route: "ranking.levels" },
                { label: "Conquistas", route: "ranking.achievements" },
                { label: "Placas", route: "ranking.plates" },
            ],
        };
    },
};
</script>
