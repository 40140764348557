<template>
    <router-link
        tag="li"
        class="block bg-gray-300 hover:cursor-pointer hover:bg-gray-300 hover:bg-gray-300 dark:bg-slate-800 dark:bg-slate-800 dark:hover:bg-slate-900"
        :to="{
            name: 'audit.trackings',
            params: {
                audit_id: audit.id,
            },
        }"
    >
        <div class="block">
            <div class="flex items-center px-4 py-5">
                <div class="flex min-w-0 flex-1 items-center">
                    <div class="flex-shrink-0">
                        <img
                            class="h-12 w-12 rounded-full"
                            :src="
                                audit.user.avatar ||
                                'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                    audit.email
                            "
                        />
                    </div>
                    <div
                        class="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4"
                    >
                        <div>
                            <p
                                class="truncate text-sm font-medium text-gray-900 dark:text-indigo-600"
                            >
                                {{ audit.name }}
                            </p>
                            <p
                                class="mt-2 flex items-center text-sm text-gray-500"
                            >
                                <EnvelopeIcon
                                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                />
                                <span class="truncate">
                                    {{ audit.email }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div
                        class="ml-auto hidden space-y-3 dark:text-white md:block"
                    >
                        <p class="flex items-center flex-wrap text-sm dark:text-white">
                            <ClockIcon
                                class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                            />
                            <time :datetime="audit.created_at">
                                {{ audit.created_at }}
                            </time>

                            <span class="ml-2 bg-indigo-700 px-1.5 py-0.5 rounded shadow-lg" v-tooltip="'Data de vencimento: ' + audit.due_date">
                                {{audit.due_days_left}}
                            </span>
                        </p>
                        <div class="mt-2 flex items-center justify-end text-sm">
                            <audit-status :audit="audit" size="sm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import UsersModal from "../manager/modals/UsersModal.vue";
import Users from "../manager/Users.vue";
import {
    CheckCircleIcon,
    ChevronDownIcon,
    ClockIcon,
    EnvelopeIcon,
    NoSymbolIcon,
    ExclamationCircleIcon,
} from "@heroicons/vue/24/outline";
import UpdateModal from "../manager/modals/UpdateModal.vue";
import DestroyModal from "../manager/modals/DestroyModal.vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import AuditStatus from "./AuditStatus.vue";

export default {
    components: {
        AuditStatus,
        ExclamationCircleIcon,
        ClockIcon,
        CheckCircleIcon,
        NoSymbolIcon,
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        ChevronDownIcon,
        DestroyModal,
        UpdateModal,
        EnvelopeIcon,
        Users,
        UsersModal,
    },
    props: ["audit"],

    data() {
        return {
            menu: [
                { label: "Usuários", modal: "users" },
                { label: "Alterar", modal: "update" },
                { label: "Remover", modal: "destroy" },
            ],
            modals: {
                users: false,
                update: false,
                destroy: false,
            },
        };
    },
};
</script>
