<template>
    <div>
        <div class="my-6">
            <div class="space-x-2 text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-cog" />
                Configurações Financeiras
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Recebimento</span
                    >
                    <div class="mt-1.5">
                        <payment-settings
                            :status="settings.allow_payment_in_installments"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Recebimento como fornecedor</span
                    >
                    <div class="mt-1.5">
                        <payment-settings
                            :status="settings.allow_payment_in_installments_for_provider"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Reembolso de comissão do fornecedor</span
                    >
                    <div class="mt-1.5">
                        <refund-provider-from-producer
                            :status="settings.refund_providers_commission_from_producer"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Cobrança da taxa da plataforma no reembolso</span
                    >
                    <div class="mt-1.5">
                        <is-allowed
                            :status="settings.allow_charge_platform_fee_upon_refund"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Cancelamento de reembolso</span
                    >
                    <div class="mt-1.5">
                        <is-allowed
                            :status="settings.is_refund_locked"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Saque</span
                    >
                    <div class="mt-1.5">
                        <is-allowed
                            :status="!settings.is_cashout_locked"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Saque de saldo em análise</span
                    >
                    <div class="mt-1.5">
                        <is-allowed
                            :status="settings.allow_cashout_under_review"
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Novo checkout internacional</span
                    >
                    <div class="mt-1.5">
                        <is-allowed
                            :status="settings.allow_new_international_checkout"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="space-x-2 text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-coins" />
                Antecipação
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Status</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.can_anticipate ? 'Ativa' : 'Inativa' }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Versão</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.anticipation_version }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.anticipation_fee }}%</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Limite</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.anticipation_limit ?? 60 }}%</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-dollar-sign" />
                Cobraça Fixa
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa fixa nacional (R$)</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{
                            $root.formatCurrency(settings.platform_fixed_fee)
                        }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa fixa internacional (R$)</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{
                            $root.formatCurrency(settings.international_fixed_fee)
                        }}</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="space-x-2 text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-credit-card" />
                Cartão de Crédito e Dois Cartões
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Tempo para saque</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.credit_card_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa da plataforma</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.platform_fee_credit_card }}%</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa de juros por parcela</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.installment_fee_credit_card }}%</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="space-x-2 text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-barcode" />
                Boleto
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Tempo para saque</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.bank_slip_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa da plataforma</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.platform_fee_bank_slip }}%</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa de juros por parcela</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.installment_fee_bank_slip }}%</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-brands fa-pix" />
                Pix
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Tempo para saque</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.pix_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa da plataforma</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.platform_fee_pix }}%</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-globe" />
                Pagamento Internacional
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Tempo para saque</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.international_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Taxa da plataforma</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.international_platform_fee }}%</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-star" />
                Programa de Indicações
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                        >Tempo para saque</span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.referral_cashout_days }} dias</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa fa-money-bill-transfer" />
                Cashback
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 text-sm text-white mt-1">
                <div class="flex flex-col">
                    <span class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium">
                        Tipo de taxa
                    </span>
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.cashback_type == 'percentage' ? "Percentual" : "Valor Fixo" }}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium">
                        Valor da taxa
                    </span>
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.cashback_fee }}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium">
                        Origem
                    </span>
                    <span class="text-gray-600 dark:text-gray-200">
                        {{  settings.cashback_origin === 'platform' ? "Plataforma" : 
                            settings.cashback_origin === 'interest' ? "Juros" :
                            "Todos" }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IsAllowed from "./IsAllowed.vue";
import PaymentSettings from "./PaymentSettings.vue";
import RefundProviderFromProducer from "./RefundProviderFromProducer.vue";

export default {
    props: ["settings"],
    components: {
        IsAllowed,
        PaymentSettings,
        RefundProviderFromProducer,
    },
};
</script>
