<template>
    <modal
        size="lg"
        :open="show"
        @closeModal="
            show = false;
            $emit('closeModal');
        "
        @closeModalAndFetch="
            show = false;
            $emit('closeAndFetchModal');
        "
    >
        <form
            class="flex w-full flex-col flex-wrap space-y-3"
            @submit.prevent="submit"
        >
            <h1 class="border-b border-slate-500 pb-2">
                <span v-if="askCancel">Tem certeza que deseja reembolsar essa cobrança e cancelar todas as próximas cobranças desse pedido?</span>
                <span v-else>Tem certeza que deseja reembolsar essa cobrança?</span>
            </h1>
            <label for="reason" class="sr-only">Reason</label>
            <div v-if="errors.length > 0" class="alert">
                <div v-for="error in errors" :key="error">
                    {{ error }}
                </div>
            </div>
            <textarea
                id="reason"
                name="reason"
                v-model="reason"
                class="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Qual o motivo do reembolso?"
                :required="true"
            ></textarea>
            <div class="mt-5 flex flex-row-reverse">
                <submit-button
                    :loading="loading"
                    :disabled="loading"
                    @keydown.enter="submit"
                >
                    Enviar
                </submit-button>
                <button
                    :disabled="loading"
                    type="button"
                    class="mr-2 inline-flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="$emit('closeModal')"
                >
                    Cancelar
                </button>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "../../../components/layouts/Modal.vue";
import SubmitButton from "../../../components/forms/SubmitButton.vue";

export default {
    props: ["transaction", "show", "askCancel", "order"],
    components: { SubmitButton, Modal },
    data() {
        return {
            loading: false,
            reason: '',
            permissions: [],
            errors: [],
            message: null,
        };
    },
    methods: {
        submit() {

            this.errors = [];
            this.message = null;
            this.loading = true;

            let cancel = !!this.askCancel;

            axios.post(
                    "/api/order/" +
                    this.order.id +
                    "/transactions/" +
                    this.transaction.id +
                    "/refund",
                    {
                        permissions: this.permissions,
                        cancel: cancel,
                        reason: this.reason,
                    },
                )
                .then(({ data }) => {
                    this.$emit("closeModalAndFetch");

                    this.$notify(
                        {
                            group: "success",
                            title: "Success",
                            text:
                                data?.message ||
                                "Solicitação realizada com sucesso!",
                        },
                        4000,
                    );
                })
                .catch(({ response }) => {
                    this.$notify(
                        {
                            group: "error",
                            title: "Error",
                            text:
                                response?.data?.message ||
                                response?.data?.errors[0] ||
                                "Não foi possível concluir essa solicitação.",
                        },
                        4000,
                    );

                    this.loading = false;
                }
            );
        },
    },
};
</script>
