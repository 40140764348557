<template>
    <div class="bg-white dark:bg-slate-800 rounded-lg shadow p-4">
        <div class="flex items-center">
            <component :is="icon" class="h-6 w-6 text-gray-400 dark:text-gray-500" />
            <h3 class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">{{ title }}</h3>
        </div>
        <div class="mt-2">
            <div class="text-2xl font-semibold text-gray-900 dark:text-white">{{ value }}</div>
            <p class="mt-1 text-sm text-gray-600 dark:text-gray-300">{{ subtitle }}</p>
        </div>
    </div>
</template>

<script>
import { 
    ExclamationTriangleIcon, 
    ShieldExclamationIcon,
    EnvelopeIcon 
} from '@heroicons/vue/24/outline'

export default {
    components: {
        ExclamationTriangleIcon,
        ShieldExclamationIcon,
        EnvelopeIcon
    },
    props: {
        title: String,
        value: [String, Number],
        subtitle: String,
        icon: String
    }
}
</script>
