<template>
    <filters
        class="my-4"
        v-model="filters"
        date-filter
        search-filter
        @fetch="
            pagination.current_page = 1;
            fetch();
        "
    />

    <table-component
        :loading="loading"
        :headers="[]"
        :pagination="pagination"
        v-model="pagination"
        @fetch="fetch"
    >
        <template v-slot:thead>
            <thead class="dark:text-white">
            <tr>
                <th
                    v-for="(header, index) in [
                            'Id',
                            'Status',
                            'Usuário',
                            'Nível',
                            'Quantidade',
                            'Rastreio',
                            'Ações',
                        ]"
                    scope="col"
                    class="p-3 text-left text-sm font-medium"
                    :class="{
                            'hover:cursor-pointer': sortable.includes(header),
                        }"
                    @click="sortField(header, sort.direction)"
                >
                    <div class="flex items-center">
                        <div v-if="sort && sort.field === header">
                            <component
                                :is="
                                        sort.direction === 'asc'
                                            ? 'ArrowDownIcon'
                                            : 'ArrowUpIcon'
                                    "
                                class="mr-1 h-3 w-3"
                            />
                        </div>
                        <span>{{ header }}</span>
                    </div>
                </th>
            </tr>
            </thead>
        </template>

        <tr v-for="plate in plates">
            <td class="table-cell">
                {{ plate.id }}
            </td>
            <td class="table-cell">
                <span class="font-semibold rounded-full p-1.5 px-4 truncate"
                      :class="statusColors[plate.status]"
                >
                    {{statusTypes[plate.status] }}
                </span>
            </td>
            <td class="table-cell">
                <a :href="`/user/${plate.user.id}/plates`" target="_blank">
                    <p>{{ plate.user.name }}</p>
                    <p>{{ plate.user.email }}</p>
                </a>
            </td>
            <td class="table-cell">
                <span class="font-semibold">{{ plate.level.label }}
                    <small>({{ formatCurrency(plate.level.amount) }})</small>
                </span>
            </td>
            <td class="table-cell">
                {{ plate.quantity }}
            </td>
            <td class="table-cell">
                <p>Transportadora: {{ plate?.shipping_provider?.name ?? 'Sem Transportadora' }}</p>
                <p>
                    <a v-if="plate.tracking_url" :href="plate.tracking_url" target="_blank">
                        {{plate.tracking_code}}
                    </a>
                    <span v-else>Código: {{plate.tracking_code}}</span>
                </p>
            </td>
            <td class="table-cell">
                <div class="flex items-center space-x-2">
                    <button
                        @click.prevent="openModalToEdit(plate.id)"
                        type="button"
                        class="inline-flex items-center justify-center px-2 py-1 ml-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
                    >
                        Editar
                    </button>
                    <button
                        @click.prevent="handleDelete(plate.id)"
                        type="button"
                        class="inline-flex items-center justify-center px-2 py-1 ml-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-red-500 rounded-md shadow hover:bg-red-400"
                    >
                        Excluir
                    </button>
                </div>
            </td>
        </tr>
    </table-component>

    <form-plate-modal
        v-if="isModalOpen"
        @closeModal="closeModal"
        :plate-id="this.plateId"
        @fetch="fetch"
        @refetchPlates="fetch"
    />
</template>

<script>
import {TransitionRoot} from "@headlessui/vue";
import FormToggle from "../../../components/forms/FormToggle.vue";
import VueSelect from "../../../components/forms/VueSelect.vue";
import TictoLoading from "../../../components/layouts/TictoLoading.vue"
import {useToast} from "vue-toastification";
import FormPlateModal from "../../ranking/modals/FormPlateModal.vue";
import Filters from "../../../components/layouts/Filters.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";

export default {
    props: ['user'],
    components: {
        TableComponent,
        Filters,
        FormPlateModal,
        VueSelect,
        TransitionRoot,
        FormToggle,
        TictoLoading
    },

    setup() {
        const rankingTypes = {
            billing: "Comissionamento"
        }
        const toast = useToast();
        return {toast, rankingTypes};
    },

    data() {
        return {
            statusTypes: [],
            statusColors: [],
            isModalOpen: false,
            plateId: null,

            plates: [],

            loading: false,
            filters: {},

            sortable: ["Id"],
            sort: {
                field: "id",
                direction: "asc",
            },
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    mounted() {
        this.fetch();
        this.fetchStatus();
    },

    computed: {
        formatCurrency(value) {
            return (value) => {
                return new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(value / 100);
            };
        },
    },
    methods: {
        sortField(field, direction) {
            if (!this.sortable.includes(field)) {
                return;
            }
            if (field === this.sort.field) {
                direction = direction === "asc" ? "desc" : "asc";
            }

            this.sort.direction = direction;
            this.sort.field = field;
            this.fetch();
        },
        closeModal() {
            this.isModalOpen = false;
            this.plateId = null;
        },
        openModal() {
            this.isModalOpen = true;
        },
        openModalToEdit(id) {
            this.isModalOpen = true;
            this.plateId = id;
        },
        fetch() {
            this.loading = true;
            this.plates = []

            let sort = false;
            if (this.sort.field) {
                let sortField = this.sort.field.toLowerCase();
                sort =
                    this.sort.direction === "desc"
                        ? `-${sortField}`
                        : sortField;
            }

            axios
                .get(`/api/plates/user/${this.user.id}`, {
                    params: {
                        page: this.pagination.current_page,
                        ...this.filters,
                        sort,
                    },
                })
                .then(({data}) => {
                    this.plates = data.data;
                    this.pagination = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        fetchStatus() {
            axios
                .get("/api/plates/statuses")
                .then(({data}) => {
                    this.statusTypes = data.types;
                    this.statusColors = data.colors;

                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        handleDelete(id) {
            if (confirm("Tem certeza que deseja excluir a placa?")) {
                axios
                    .delete(`/api/plates/${id}`)
                    .then(({data}) => {
                        if (data) {
                            this.toast.success("Placa excluída com sucesso");
                            this.fetch();
                        }
                    })
                    .catch(
                        ({
                             response: {
                                 data: {message},
                             },
                         }) => {
                            this.toast.error(message);
                        }
                    );
            }
        },
    },
};
</script>
