<template>
    <form class="space-y-4 md:space-y-3" @submit.prevent="submit">
        <div>
            <label
                for="text"
                class="block mb-2 text-sm font-medium text-white"
                >Texto</label
            >
            <div class="bg-white">
                <QuillEditor
                    id="text"
                    :toolbar="[
                        'bold',
                        'italic',
                        'link'
                    ]"
                    v-model:content="form.text"
                    contentType="html"
                    style="min-height:100px;"
                    required
                />
            </div>
        </div>

        <form-toggle
            v-model="form.is_active"
            :loading="loading"
            label="Ativo?"
        />

        <submit-button :loading="loading" class="ml-auto">Salvar</submit-button>
    </form>
</template>

<script>
import { vMaska } from "maska";
import SubmitButton from "../forms/SubmitButton.vue";
import FormToggle from "../forms/FormToggle.vue";
import { useToast } from "vue-toastification";
import { QuillEditor } from "@vueup/vue-quill";

export default {
    props: { legalTerm: { type: Object, default: null } },
    directives: { maska: vMaska },
    components: { SubmitButton, FormToggle, QuillEditor },
    emits: ["fetch"],
    data() {
        return {
            show: false,
            loading: false,
            form: {
                text: "",
                is_active: true,
            },
        };
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    mounted() {
        if (this.legalTerm) {
            this.form = {
                text: this.legalTerm.text,
                is_active: this.legalTerm.is_active,
            };
        }
    },

    methods: {
        async submit() {
            if (this.form.text == '<p><br></p>' || !this.form.text) {
                this.loading = false; 
                this.toast.error("Preencha o texto.");
                return;
            }

            this.loading = true;

            let promise = this.legalTerm
                ? axios.put(`/api/legal-terms/${this.legalTerm.id}`, this.form)
                : axios.post("/api/legal-terms", this.form);

            promise
                .then((response) => {
                    this.loading = false;
                    this.$emit("fetch");
                    this.toast.success("Termo jurídico salvo com sucesso!");
                })
                .catch((error) => {
                    this.loading = false;
                    this.toast.error("Não foi possível concluir essa solicitação.");
                });
        },
    },
};
</script>
