<template>
    <div class="w-full">
        <chart-skeleton v-if="loading"/>
        <highcharts v-if="!loading && chart" :constructor-type="'chart'" :options="chart"></highcharts>
    </div>
</template>

<script>
import ChartSkeleton from "../../../components/layouts/ChartSkeleton.vue";
import { defineComponent } from 'vue';

export default defineComponent({
    components: { 
        ChartSkeleton
    },
    props: {
        loading: { required: false, type: Boolean, default: false },
        series: { required: true, type: Array, default: () => []}
    },
    data() {
        return {
            chart: false,
        };
    },

    watch: {
        series: {
            handler(newVal) {
                if (!newVal?.length) return;
                
                let chart = this.chartObject();
                chart.series = [...newVal]; // Alterado aqui
                this.chart = chart;
            },
            immediate: true
        },
    },

    methods: {
        chartObject() {
            return {
                tooltip: {
                    formatter: function() {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ` + point.y.toLocaleString("pt-br");
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            };
        }
    }
});
</script>
