<template>
    <div
        class="overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="mx-auto w-full rounded-2xl">
                <div class="flex justify-between">
                    <h3
                        class="mt-1 text-lg font-medium leading-6 dark:text-white"
                    >
                        Configurações Financeiras
                    </h3>
                    <button
                        v-if="!audit"
                        :disabled="loading"
                        type="button"
                        class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-indigo-500 bg-indigo-500 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-100 sm:mt-0 sm:w-auto sm:text-sm"
                        @click="openModal()"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-cog"
                            class="mr-1 dark:text-white"
                            aria-hidden="true"
                        />
                        Editar
                    </button>
                </div>

                <ticto-loading v-if="loading" />
                <div v-else class="mt-3 border-t dark:border-slate-900">
                    <div class="flex-grow border-t border-gray-400"></div>
                    <dl class="divide-y divide-slate-400">
                        <div class="my-6">
                            <div
                                v-if="audit"
                                class="mb-3 border-l-4 border-yellow-600 bg-yellow-200 p-4 text-yellow-700"
                                role="alert"
                            >
                                <p class="font-medium">
                                    A auditoria está em andamento para este
                                    usuário. Alterações na configuração
                                    permanecerão desativadas até a conclusão da
                                    auditoria.
                                </p>
                            </div>
                            <div
                                v-if="!audit"
                                class="space-x-2 text-sm font-bold uppercase text-gray-400"
                            >
                                <font-awesome-icon icon="fa-solid fa-wallet" />
                                Gateways de pagamento
                            </div>
                            <div v-if="!audit">
                                <form @submit.prevent="submitGateways">
                                    <div class="mt-4">
                                        <div v-for="(gateway, index) in gateways">
                                            <div class="grid grid-cols-1 md:grid-cols-5 gap-2 text-sm text-white mb-4 md:mb-0">
                                                <div class="flex flex-col">
                                                    <span
                                                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                                                        >Gateway
                                                            {{
                                                                index + 1
                                                            }}</span
                                                    >
                                                    <span
                                                        class="capitalize text-gray-600 dark:text-gray-200"
                                                    >
                                                        {{
                                                            gateway.name
                                                        }}</span
                                                    >
                                                </div>
                                                <div class="flex flex-col">
                                                    <span
                                                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                                                    >
                                                        Prioridade (Nacional)
                                                    </span>
                                                    <span
                                                        class="text-gray-600 dark:text-gray-200"
                                                    >
                                                        <input
                                                            type="number"
                                                            min="1"
                                                            :max="
                                                                gateways.length
                                                            "
                                                            v-model="
                                                                this.form
                                                                    .gateways[
                                                                    index
                                                                ].priority
                                                            "
                                                            class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                        />
                                                    </span>
                                                </div>
                                                <div class="flex flex-col">
                                                    <span
                                                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                                                        >Habilitado (Nacional)</span
                                                    >
                                                    <span
                                                        class="text-gray-600 dark:text-gray-200"
                                                    >
                                                        <form-toggle
                                                            v-model="
                                                                this.form
                                                                    .gateways[
                                                                    index
                                                                ].is_active
                                                            "
                                                        ></form-toggle>
                                                    </span>
                                                </div>
                                                <div class="flex flex-col">
                                                    <span
                                                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                                                    >
                                                        Prioridade (Internacional)
                                                    </span>
                                                    <span
                                                        class="text-gray-600 dark:text-gray-200">
                                                        <input
                                                            type="number"
                                                            min="1"
                                                            :max="
                                                                gateways.length
                                                            "
                                                            v-model="
                                                                this.form
                                                                    .gateways[
                                                                    index
                                                                ].international_priority
                                                            "
                                                            class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                        />
                                                    </span>
                                                </div>
                                                <div class="flex flex-col">
                                                    <span
                                                        class="text-sm font-bold text-gray-600 dark:text-gray-400 dark:font-medium"
                                                        >Habiliado (Internacional)</span
                                                    >
                                                    <span
                                                        class="text-gray-600 dark:text-gray-200"
                                                    >
                                                        <form-toggle
                                                            v-model="
                                                                this.form
                                                                    .gateways[
                                                                    index
                                                                ].is_international
                                                            "
                                                        ></form-toggle>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        :disabled="loading"
                                        type="submit"
                                        :class="{ 'cursor-not-allowed': loading }"
                                        class="inline-flex max-w-[195px] items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                                    >
                                        Salvar
                                    </button>
                                </form>
                            </div>
                        </div>

                        <financial-methods-info :settings="settings" />

                        <edit-financial-settings-modal
                            :settings="settings"
                            :user="user"
                            :open="edit_financial_modal"
                            v-if="edit_financial_modal"
                            @closeModal="closeModal()"
                            @closeModalAndFetch="closeAndFetchModal()"
                        />
                    </dl>
                </div>
            </div>
        </div>
        <UserFinancialLogs v-if="!loading" :user="user" />
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import EditFinancialSettingsModal from "../modals/EditFinancialSettingsModal.vue";
import FinancialMethodsInfo from "../../../components/layouts/FinancialMethodsInfo.vue";
import FormToggle from "../../../components/forms/FormToggle.vue";
import HelperMixin from "../../../mixins/HelperMixin.vue";
import TictoLoading from "../../../components/layouts/TictoLoading.vue";
import UserFinancialLogs from "./UserFinancialLogs.vue";
import AnticipationStatus from "../../../components/layouts/AnticipationStatus.vue";

export default {
    props: ["user"],
    components: {
        TictoLoading,
        AnticipationStatus,
        FinancialMethodsInfo,
        EditFinancialSettingsModal,
        FinancialMethodsInfo,
        FormToggle,
        TictoLoading,
        UserFinancialLogs,
    },
    mixins: [HelperMixin],

    data() {
        return {
            audit: false,
            loading: true,
            settings: {},
            gateways: {},
            gatewaysUser: {},
            form: {
                gateways: [],
            },
            edit_financial_modal: false,
        };
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    mounted() {
        this.fetch();
        this.fetchGateways();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id + "/settings")
                .then(({ data }) => {
                    this.settings = data;
                })
                .finally(() => {
                    this.loading = false;
                });

            const params = {};
            params["filter[user_id]"] = this.$route.params.user_id;

            axios
                .get("/api/audit", {
                    params,
                })
                .then(({ data }) => {
                    this.audit = data.data.find((audit) => {
                        return audit.status === "open";
                    });
                });
        },
        fetchGateways() {
            this.loading = true;
            axios
                .get("/api/gateway")
                .then(({ data }) => {
                    this.gateways = data;

                    axios
                        .get("/api/user/" + this.user.id + "/gatewayUser")
                        .then((response) => {
                            this.gatewaysUser = response.data;

                            data?.map((gateway, i) => {
                                let gatewayUser = response.data.find((item) => {
                                    return item.gateway_id === gateway.id;
                                });

                                this.form.gateways[i] = {
                                    gateway_id: gateway.id,
                                    is_active:
                                        gatewayUser?.is_active ??
                                        gateway?.is_active,
                                    priority:
                                        gatewayUser?.priority ??
                                        gateway?.priority,
                                    is_international:
                                        gatewayUser?.is_international ??
                                        gateway?.is_international,
                                    international_priority:
                                        gatewayUser?.international_priority ??
                                        gateway?.international_priority,
                                };
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        submitGateways() {
            this.loading = true;

            var noDuplicates = new Set(
                this.form.gateways?.map((gateway) => gateway.priority),
            );

            if (noDuplicates.size < this.form.gateways?.length) {
                this.toast.error("As prioridades não podem ser iguais");
                this.loading = false;

                return;
            }

            axios
                .post(
                    "/api/user/" + this.user.id + "/gateway",
                    this.form.gateways,
                )
                .then(({ data }) => {
                    this.toast.success("Configurações salvas com sucesso.");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openModal() {
            this.edit_financial_modal = true;
        },
        closeModal() {
            this.edit_financial_modal = false;
        },
        closeAndFetchModal() {
            this.edit_financial_modal = false;
            this.fetch();
        },
    },
};
</script>
