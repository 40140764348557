<template>
    <div class="chat-container mx-auto max-w-md p-4">
        <div class="messages-container mb-4 h-64 overflow-y-auto">
            <div
                v-for="message in messages"
                :key="message.id"
                :class="messageClass(message)"
            >
                <span>{{ message.content }}</span>
            </div>
        </div>
        <div class="message-input">
            <input
                v-model="newMessage"
                @keyup.enter="sendMessage"
                type="text"
                placeholder="Digite sua mensagem..."
                class="w-full border p-2"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            messages: [],
            newMessage: "",
            openAiToken: "#",
        };
    },
    methods: {
        messageClass(message) {
            return {
                "bg-blue-100 p-2 rounded mb-2": message.sender === "user",
                "bg-green-100 p-2 rounded mb-2": message.sender === "ai",
            };
        },
        sendMessage() {
            if (!this.newMessage) return;

            const userMessage = {
                content: this.newMessage,
                sender: "user",
                id: Date.now(), // ID simples baseado em timestamp para o exemplo
            };
            this.messages.push(userMessage);

            // Aqui você faz a chamada para a API da OpenAI
            this.fetchResponse(this.newMessage);

            // Reset input
            this.newMessage = "";
        },
        async fetchResponse(message) {
            try {
                const response = await fetch(
                    "https://api.openai.com/v1/engines/davinci-codex/completions",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.openAiToken}`,
                        },
                        body: JSON.stringify({
                            prompt: message,
                            max_tokens: 150,
                        }),
                    },
                );
                if (!response.ok) throw new Error("Erro na resposta da API");

                const data = await response.json();
                const aiMessage = {
                    content: data.choices[0].text.trim(),
                    sender: "ai",
                    id: Date.now(), // ID simples baseado em timestamp para o exemplo
                };
                this.messages.push(aiMessage);
            } catch (error) {
                console.error("Houve um erro ao obter a resposta:", error);
            }
        },
    },
};
</script>

<style>
.messages-container {
    background-color: #f8f8f8;
}
</style>
