<template>
    <modal :open="modal" @close="$emit('closeModal')">
        <h3
            class="mb-3 border-b border-slate-400 pb-2 text-lg font-bold leading-6 text-white"
        >
            Editar Usuário
        </h3>
        <form @submit.prevent="handleSubmit">
            <div class="mb-2">
                <label
                    for="name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Pessoa</label
                >
                <select
                    v-model="form.type"
                    id="type"
                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                    <option value="person">Física</option>
                    <option value="company">Jurídica</option>
                </select>
            </div>

            <div class="mb-2">
                <label
                    for="name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Nome</label
                >
                <input
                    v-model="form.name"
                    type="text"
                    name="name"
                    id="name"
                    class="text-input"
                    required
                />
            </div>

            <div class="mb-2" v-if="form.type === `company`">
                <label
                    for="social_name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Nome Social</label
                >
                <input
                    v-model="form.social_name"
                    type="text"
                    name="social_name"
                    id="social_name"
                    class="text-input"
                    required
                />
            </div>

            <div v-if="form.type === `person`">
                <div class="mb-2">
                    <label
                        for="birthDate"
                        class="mb-2 block text-sm font-medium text-white"
                        >Data de Nascimento</label
                    >
                    <input
                        v-maska
                        data-maska="##/##/####"
                        v-model="form.birth"
                        type="text"
                        name="birthDate"
                        id="birthDate"
                        class="text-input"
                        required
                    />
                </div>
                <div class="mb-2">
                    <label
                        for="cpf"
                        class="mb-2 block text-sm font-medium text-white"
                        >CPF</label
                    >
                    <input
                        v-model="form.cpf"
                        v-maska
                        data-maska="###.###.###-##"
                        type="text"
                        name="cpf"
                        id="cpf"
                        class="text-input"
                        required
                    />
                </div>
            </div>
            <div v-else>
                <div class="mb-2">
                    <label
                        for="cnpj"
                        class="mb-2 block text-sm font-medium text-white"
                        >CNPJ</label
                    >
                    <input
                        v-model="form.cnpj"
                        v-maska
                        data-maska="##.###.###/####-##"
                        type="text"
                        name="cnpj"
                        id="cnpj"
                        class="text-input"
                        required
                    />
                </div>

                <h3
                    class="mb-3 border-b border-slate-400 pb-2 text-lg font-bold font-medium leading-6 text-white"
                >
                    Sócio Administrador
                </h3>

                <div class="mb-2">
                    <label
                        for="company_partner_cpf"
                        class="mb-2 block text-sm font-medium text-white"
                        >Nome</label
                    >
                    <input
                        v-model="form.company_partner.name"
                        type="text"
                        name="company_partner_name"
                        id="company_partner_name"
                        class="text-input"
                        required
                    />
                </div>
                <div class="mb-2">
                    <label
                        for="company_partner_cpf"
                        class="mb-2 block text-sm font-medium text-white"
                        >CPF</label
                    >
                    <input
                        v-model="form.company_partner.cpf"
                        v-maska
                        data-maska="###.###.###-##"
                        type="text"
                        name="company_partner_cpf"
                        id="company_partner_cpf"
                        class="text-input"
                        required
                    />
                </div>
                <div class="mb-2">
                    <label
                        for="birthDate"
                        class="mb-2 block text-sm font-medium text-white"
                        >Data de Nascimento</label
                    >
                    <input
                        v-model="form.company_partner.birth"
                        v-maska
                        data-maska="##/##/####"
                        type="text"
                        name="birthDate"
                        id="birthDate"
                        class="text-input"
                        required
                    />
                </div>
            </div>

            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    :disabled="loading"
                    type="submit"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Enviar
                </button>
                <button
                    :disabled="loading"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="closeModal()"
                    ref="cancelButtonRef"
                >
                    Cancelar
                </button>
            </div>
        </form>
    </modal>

    <modal
        :open="showDocumentChangeWarning"
        @close="showDocumentChangeWarning = false"
    >
        <div class="p-6 text-white">
            <h3 class="mb-4 text-lg font-medium leading-6 text-white">
                Atenção!
            </h3>
            <p class="mb-2">
                Alterar o {{ form.type === "person" ? "CPF" : "CNPJ" }} irá:
            </p>
            <ul class="mb-4 list-inside list-disc text-white">
                <li>Retornar o status da conta para "Pendente Correção"</li>
                <li>Excluir todos os documentos associados</li>
                <li>Excluir todas as contas bancárias associadas</li>
            </ul>
            <p class="mb-4">Tem certeza que deseja continuar?</p>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    @click="confirmSubmit"
                >
                    Sim, continuar
                </button>
                <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="showDocumentChangeWarning = false"
                >
                    Cancelar
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import dayjs from "dayjs";
import { vMaska } from "maska";
import { mapActions, mapWritableState } from "pinia";
import { useToast } from "vue-toastification";
import { Store } from "../../stores/store.js";
import Modal from "../layouts/Modal.vue";
import TictoLoading from "../layouts/TictoLoading.vue";

export default {
    props: { modal: { type: Boolean, default: false } },
    components: { Modal, TictoLoading },
    directives: { maska: vMaska },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            form: {
                type: "",
                name: "",
                is_emancipated: false,
                birth: "",
                social_name: "",
                cpf: "",
                cnpj: "",
                company_partner: {
                    name: "",
                    cpf: "",
                    birth: "",
                },
            },
            showDocumentChangeWarning: false,
            originalDocument: "",
        };
    },

    computed: {
        ...mapWritableState(Store, ["user", "storeCompliance"]),
    },

    watch: {
        modal(bool) {
            if (bool && this.storeCompliance) {
                this.form.name = this.storeCompliance.name;
                this.form.birth = this.storeCompliance.birth;
                this.form.social_name = this.storeCompliance.social_name;
                this.form.cpf = this.storeCompliance.cpf;
                this.form.is_emancipated = this.is_emancipated;
                this.form.cnpj = this.storeCompliance.cnpj;
                this.form.company_partner_cpf =
                    this.storeCompliance.company_partner_cpf;
                this.form.type = this.storeCompliance.type;

                if (this.storeCompliance.company_partner) {
                    this.form.company_partner =
                        this.storeCompliance.company_partner;

                    this.form.company_partner.birth = this.storeCompliance
                        .company_partner.birth
                        ? dayjs(
                              this.storeCompliance.company_partner.birth,
                          ).format("DD/MM/YYYY")
                        : "";
                }

                this.originalDocument =
                    this.storeCompliance.type === "person"
                        ? this.storeCompliance.cpf
                        : this.storeCompliance.cnpj;
            }
        },
    },

    methods: {
        ...mapActions(Store, ["fetchCompliance"]),
        closeModal() {
            this.$emit("closeModal");
        },
        handleSubmit() {
            if (this.checkDocumentChange()) {
                console.log("change");
                this.showDocumentChangeWarning = true;
            } else {
                this.submit();
            }
        },
        checkDocumentChange() {
            const currentDocument =
                this.form.type === "person" ? this.form.cpf : this.form.cnpj;
            console.log(currentDocument, this.originalDocument);

            if (this.originalDocument !== null) {
                return (
                    currentDocument.replace(/\D/g, "") !==
                    this.originalDocument.replace(/\D/g, "")
                );
            } else {
                return true;
            }
        },
        confirmSubmit() {
            this.showDocumentChangeWarning = false;
            this.submit();
        },
        submit() {
            this.loading = true;
            axios
                .put("/api/user/" + this.storeCompliance.id, this.form)
                .then(async ({ data }) => {
                    await this.fetchCompliance();
                    this.toast.success("Salvo com sucesso.");
                    this.$emit("closeModalAndFetch");
                    this.loading = false;
                })
                .catch(async ({ response }) => {
                    if (response.data.hasOwnProperty("errors")) {
                        this.toast.error(response.data.errors[0]);
                    } else {
                        this.toast.error(response.data.message);
                    }

                    this.loading = false;
                });
        },
    },
};
</script>
