<template>
    <h3 class="my-2 text-lg font-bold font-medium text-gray-400">
        Histórico de Reembolso
    </h3>
    <div class="mb-3 mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full sm:px-6 lg:px-8">
            <div
                class="not-prose relative overflow-hidden overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25"
            >
                <table class="w-full table-auto border-collapse text-sm">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                            >
                                Status
                            </th>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                            >
                                Autor
                            </th>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                            >
                                Observação
                            </th>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                            >
                                Data
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                        <tr
                            v-for="refundHistory in refundHistories"
                            :key="refundHistory.id"
                        >
                            <td
                                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                            >
                                <refund-status-label
                                    :status="refundHistory.status"
                                />
                            </td>
                            <td
                                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                            >
                                {{
                                    refundHistory.user?.id ===
                                    order?.customer?.id
                                        ? "Comprador"
                                        : refundHistory.user === null
                                        ? "Ticto"
                                        : refundHistory.user?.email
                                }}
                            </td>
                            <td
                                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                            >
                                {{
                                    (refundHistory.status === 'failed') ? "Falha ao processar reembolso" :

                                    refundHistory.observation ?? "-"
                                }}
                            </td>
                            <td
                                class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                            >
                                {{ refundHistory.created_at }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RefundStatusLabel from "../../components/layouts/RefundStatusLabel.vue";
import Status from "../../components/layouts/Status.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";

export default {
    components: { RefundStatusLabel, Status },
    props: ["refundHistories", "order"],
    mixins: [HelperMixin],
    data() {
        return {
            loading: false,
            next_page_url: false,
            commissions: [],
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },
};
</script>
