<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                    >
                        <SvgLoader v-if="this.loading"/>
                        <form @submit.prevent="handleSave" v-else>
                            <div class="flex items-start">
                                <div
                                    class="mt-0 text-left"
                                >
                                    <dialog-title
                                        as="h3"
                                        class="text-lg leading-6 font-medium text-white"
                                    >
                                        {{ this.plateId ? "Editar" : "Cadastrar" }}
                                        Placa
                                    </dialog-title>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex flex-col">
                                <div class="grid sm:grid-cols-2 gap-2 mb-3">

                                    <div class="col-span-2 border border-gray-500 rounded-lg p-4 relative">
                                        <label class="text-white" for="user_id">
                                            Usuário
                                        </label>

                                        <div class="flex gap-2 items-center justify-between">
                                            <input
                                                v-if="!this.plateId"
                                                type="text"
                                                id="search_user"
                                                name="search_user"
                                                v-model="search_user"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="Buscar usuário por nome ou e-mail"

                                            />

                                            <svg-loader v-if="loading_user"/>
                                            <div
                                                v-if="users && users.length > 0 "
                                                class="border border-red-500 p-2 rounded-lg cursor-pointer hover:bg-red-900"
                                                @click="users = []"
                                            >
                                                <svg

                                                    class="h-6 w-6 cursor-pointer text-white rotate-45 "
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <div
                                            v-if="users && users.length > 0"
                                            class="absolute top-24 border border-gray-400 left-0 w-full flex flex-col gap-0.5 mt-2 max-h-96 overflow-y-auto bg-zinc-500 p-2 rounded-lg"
                                        >
                                            <div
                                                v-for="user in users"
                                                :key="user.id"
                                                class="cursor-pointer border border-gray-600 rounded-lg p-2 bg-black/50 hover:bg-black/75"
                                                @click="setUser(user)"
                                            >
                                                <p class="text-white">{{ user.name }}</p>
                                                <p class="text-white">{{ user.email }}</p>
                                            </div>
                                        </div>

                                        <div
                                            v-if="user_selected"
                                            class="p-4 border border-gray-500  rounded-lg mt-2 flex gap-4 justify-between items-center">
                                            <img
                                                :src="user_selected.avatar ?? 'https://ui-avatars.com/api/?name=' + user_selected.name"
                                                class="h-12 w-12 rounded-full" alt="avatar">

                                            <div class="flex-1 h-full flex flex-col justify-center">
                                                <p class="text-white">{{ user_selected.name }}</p>
                                                <p class="text-white">{{ user_selected.email }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-span-2">
                                        <label class="text-white">
                                            Status
                                        </label>
                                        <select
                                            v-model="form.status"
                                            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                        >
                                            <option
                                                v-for="(status, index) in statuses"
                                                :key="index"
                                                :value="index"
                                            >
                                                {{ status }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-span-2">
                                        <label class="text-white">
                                            Nivel
                                        </label>
                                        <select
                                            v-model="form.level_id"
                                            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                        >
                                            <option
                                                v-for="level in levels"
                                                :key="level.id"
                                                :value="level.id"
                                            >
                                                {{ level.label }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-span-2">
                                        <label class="text-white">
                                            Quantidade
                                        </label>

                                        <input
                                            type="number"
                                            min="1"
                                            id="quantity"
                                            name="quantity"
                                            v-model="form.quantity"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Quantidade"
                                            required
                                        />
                                    </div>

                                    <div class="rounded-lg border border-gray-500 p-2 col-span-2">
                                        <h2 class="text-md dark:text-white">Entrega:</h2>
                                        <hr class="my-2 border-gray-500"/>

                                        <div class="grid grid-cols-2 gap-2 pt-2">
                                            <div>
                                                <label class="text-white">
                                                    Transportadora
                                                </label>
                                                <select
                                                    v-model="form.shipping_provider_id"
                                                    class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    <option
                                                        v-for="provider in shippingProviders"
                                                        :key="provider.id"
                                                        :value="provider.id"
                                                    >
                                                        {{ provider.name }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Código de rastreio
                                                </label>
                                                <input
                                                    type="text"
                                                    id="tracking_code"
                                                    name="tracking_code"
                                                    v-model="form.tracking_code"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Código de rastreio"
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div class="rounded-lg border border-gray-500 p-2 col-span-2">
                                        <h2 class="text-md dark:text-white">Endereço:</h2>
                                        <hr class="my-2 border-gray-500"/>

                                        <div class="grid grid-cols-2 gap-2 pt-2">
                                            <div>
                                                <label class="text-white">
                                                    CEP
                                                </label>
                                                <input
                                                    type="text"
                                                    id="zip_code"
                                                    name="zip_code"
                                                    v-model="form.zip_code"
                                                    @input="form.zip_code = form.zip_code.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2')"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="CEP"
                                                    maxlength="9"
                                                    required
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Cidade
                                                </label>
                                                <input
                                                    type="text"
                                                    id="city"
                                                    name="city"
                                                    v-model="form.city"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Cidade"
                                                    maxlength="255"
                                                    required
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Estado
                                                </label>
                                                <input
                                                    type="text"
                                                    id="state"
                                                    name="state"
                                                    v-model="form.state"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Estado"
                                                    maxlength="255"
                                                    required
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    País
                                                </label>
                                                <input
                                                    type="text"
                                                    id="country"
                                                    name="country"
                                                    v-model="form.country"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="País"
                                                    maxlength="255"
                                                    required
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Rua
                                                </label>
                                                <input
                                                    type="text"
                                                    id="street"
                                                    name="street"
                                                    v-model="form.street"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Rua"
                                                    maxlength="255"
                                                    required
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Número
                                                </label>
                                                <input
                                                    type="text"
                                                    id="street_number"
                                                    name="street_number"
                                                    v-model="form.street_number"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Número"
                                                    required
                                                    maxlength="255"
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Bairro
                                                </label>
                                                <input
                                                    type="text"
                                                    id="neighborhood"
                                                    name="neighborhood"
                                                    v-model="form.neighborhood"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Bairro"
                                                    required
                                                    maxlength="255"
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Complemento
                                                </label>
                                                <input
                                                    type="text"
                                                    id="complement"
                                                    name="complement"
                                                    v-model="form.complement"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Complemento"
                                                    maxlength="255"
                                                />
                                            </div>

                                            <div>
                                                <label class="text-white">
                                                    Referência
                                                </label>
                                                <input
                                                    type="text"
                                                    id="reference"
                                                    name="reference"
                                                    v-model="form.reference"
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="Referência"
                                                    maxlength="255"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-1 flex gap-2">
                                    <button
                                        type="button"
                                        class=" w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        @click="closeModal()"
                                    >
                                        Cancelar
                                    </button>

                                    <button
                                        type="submit"
                                        class=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm"
                                    >
                                        {{ this.plateId ? "Editar" : "Cadastrar" }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import {useToast} from "vue-toastification";
import SvgLoader from "../../../components/layouts/SvgLoader.vue";
import MoneyInput from "../../../components/forms/MoneyInput.vue";
import UsersSelect from "../../../components/forms/UsersSelect.vue";

export default {
    components: {
        UsersSelect,
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        SvgLoader,
        MoneyInput
    },
    props: ["plateId"],
    setup() {
        const toast = useToast();
        return {toast};
    },
    data() {
        return {
            loading: false,
            form: {
                status: null,
                quantity: null,
                user_id: null,
                level_id: null,
                shipping_provider_id: null,
                tracking_code: null,
                zip_code: null,
                city: null,
                state: null,
                country: null,
                street: null,
                street_number: null,
                neighborhood: null,
                complement: null,
                reference: null,
                created_by: null,
            },
            levels: [],
            statuses: [],
            shippingProviders: [],
            plate: null,

            users: [],
            selectUserOpen: false,
            search_user: "",
            loading_user: false,
            user_selected: null,
        };
    },
    methods: {
        setUser(user) {
            this.users = [];
            this.form = [];

            this.form.user_id = user.id;
            this.search_user = "";
            this.user_selected = user;

            let address = user.addresses.find(address => address.is_default === true) ?? user.addresses[0];

            if (address) {
                this.form.zip_code = address.zip_code;
                this.form.city = address.city;
                this.form.state = address.state;
                this.form.country = address.country;
                this.form.street = address.street;
                this.form.street_number = address.street_number;
                this.form.neighborhood = address.neighborhood;
                this.form.complement = address.complement;
                this.form.reference = address.reference;
            }
        },
        closeModal() {
            this.$emit("closeModal");
        },
        refetchPlates() {
            this.$emit("refetchPlates");
        },
        debounce(method, timer) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer);
            }
            this.$_debounceTimer = setTimeout(() => {
                method();
            }, timer);
        },
        handleSave() {
            if(this.form.user_id){
                if (!this.plateId) {
                    axios
                    .post(`/api/plates`, {
                        ...this.form,
                    })
                    .then((response) => {
                        if (response) {
                            this.toast.success("Placa salva com sucesso!");
                            this.refetchPlates();
                            this.closeModal();
                        }
                    })
                    .catch(({response}) => {
                        if (response.data.message) {
                            this.toast.error(response.data.message);
                        } else {
                            this.toast.error("Erro ao salvar a placa");
                        }
                    });
                } else {
                    axios
                    .put(
                        `/api/plates/${this.plateId}`, {
                            ...this.form,
                        })
                    .then((response) => {
                        if (response) {
                            this.toast.success("Placa salva com sucesso");
                            this.refetchPlates();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        if(error.response.data.message) {
                            this.toast.error(error.response.data.message);
                        } else {
                            this.toast.error("Erro ao salvar placa");
                        }
                    });
                }
            } else {
                this.toast.error("Não é possível salvar a placa sem um usuário selecionado");
            }
        },
        async findOne(id) {
            this.loading = true;
            await axios
                .get(`/api/plates/${id}`)
                .then((response) => {
                    this.form = response.data;
                    this.user_selected = response.data.user;
                })
                .catch((error) => {
                    this.toast.error("Erro ao editar placa");
                })
                .finally(() => (this.loading = false));
        },
        fetchLevels() {
            axios
                .get("/api/levels")
                .then(({data}) => {
                    this.levels = data;
                })
                .catch((error) => {
                    this.toast.error("Erro ao buscar níveis");
                });
        },
        fetchStatuses() {
            axios
                .get("/api/plates/statuses")
                .then(({data}) => {
                    this.statuses = data.types;
                })
                .catch((error) => {
                    this.toast.error("Erro ao buscar status");
                });
        },
        fetchShippingProviders() {
            axios
                .get("/api/shipping-providers")
                .then(({data}) => {
                    this.shippingProviders = data;
                })
                .catch((error) => {
                    this.toast.error("Erro ao buscar transportadoras");
                });
        },
        async searchUsers(search) {
            this.loading_user = true;

            const params = {};
            params["filter[text]"] = search;

            await axios
                .get("/api/user/search", {
                    params: params,
                })
                .then(({data}) => {
                    this.users = data;
                })
                .catch((error) => {
                    this.toast.error("Erro ao buscar usuários");
                }).finally(() => {
                    this.loading_user = false;
                });
        },
    },
    watch: {
        search_user() {
            if (this.search_user.length <= 3) {
                return;
            }
            this.debounce(() => {
                this.searchUsers(this.search_user);
            }, 1000);
        },
        'form.zip_code'(zip_code) {
            zip_code = zip_code.replace(/\D/g, '');

            if (zip_code && zip_code.length === 8){

                let options = {
                    method: 'GET',
                    redirect: 'follow'
                };

                fetch(`https://viacep.com.br/ws/${zip_code}/json/`, options)
                .then(response => response.json())
                .then((result) => {
                    if (!result.erro) {
                        this.form.city = result.localidade;
                        this.form.state = result.uf;
                        this.form.street = result.logradouro;
                        this.form.neighborhood = result.bairro;
                        this.form.complement = result.complemento;
                        this.form.country = 'Brasil';
                    } else {
                        this.toast.error('CEP não encontrado');
                    }
                })
                .catch(error => this.toast.error("Erro ao buscar CEP"));
            }        
        }
    },
    mounted() {
        this.fetchLevels();
        this.fetchStatuses();
        this.fetchShippingProviders();

        if (this.plateId) {
            this.findOne(this.plateId);
        }
    }
};
</script>
