<template>   
    <h3 v-if="!loading" class="flex my-3 text-xl leading-6 font-large dark:text-white item-center">
        <store-modal
            @fetch="fetch"
            :legalTerms="legalTerms"
            @resetLegalTerms="legalTerms = null;"
        />
    </h3>
    
    <div v-if="!loading && legalTerms.length > 0" class="mb-2 mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full sm:px-6 lg:px-8">
            <div class="relative overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25">
                <table class="w-full table-auto border-collapse text-sm">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                width="80px"
                            >
                                Ações
                            </th>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                            >
                                Texto
                            </th>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                width="80px"
                            >
                                Status
                            </th>
                            <th
                                scope="col"
                                class="px-6 py-4 text-left text-sm font-medium dark:text-slate-200"
                                width="180px"
                            >
                                Data de Criação
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                        <tr v-for="legalTerm in legalTerms">
                            <td class="table-cell-sm">
                                <Menu as="div" class="relative ml-3" v-slot="{ open }">
                                    <div>
                                        <MenuButton
                                            class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none"
                                        >
                                            <button class="btn-action">
                                                <ChevronDownIcon
                                                    class="w-4 transition-all ease-in-out"
                                                    :class="{
                                                        'rotate-180': open,
                                                    }"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </MenuButton>
                                    </div>
                                    <transition
                                        enter-active-class="transition ease-out duration-100"
                                        enter-from-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-from-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95"
                                    >
                                        <MenuItems
                                            class="fixed z-10 mt-2 w-64 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                            <MenuItem v-slot="{ active }">
                                                <button
                                                    @click.prevent="toggle(legalTerm);"
                                                    :disabled="loading"
                                                    type="button"
                                                    :class="{ 'cursor-not-allowed': loading }"
                                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                >
                                                    {{ legalTerm.is_active ? 'Desativar' : 'Ativar' }}
                                                </button>
                                            </MenuItem>
                                            <MenuItem v-slot="{ active }">
                                                <button
                                                    @click.prevent="destroy(legalTerm)"
                                                    :disabled="loading"
                                                    type="button"
                                                    :class="{ 'cursor-not-allowed': loading }"
                                                    class="block px-4 py-2 text-sm text-gray-700 hover:font-bold"
                                                >
                                                    Excluir
                                                </button>
                                            </MenuItem>
                                        </MenuItems>
                                    </transition>
                                </Menu>
                            </td>
                            <td class="table-cell-sm" style="padding:0.75rem">
                                <span
                                    :title="legalTerm.text"
                                    class="block text-justify"
                                >
                                    <div v-html="legalTerm.text" /> 
                                </span>
                            </td>
                            <td class="table-cell-sm">
                                <span
                                    :title="legalTerm.is_active"
                                    class="block"
                                >
                                    {{ legalTerm.is_active ? 'Ativo' : 'Inativo' }}
                                </span>
                            </td>
                            <td class="table-cell-sm">
                                <span
                                    :title="legalTerm.created_at"
                                    class="block"
                                >
                                    {{ legalTerm.created_at }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <empty-state class="mt-4" v-if="!loading && !legalTerms.length" />

    <div v-if="loading">
        <h3 class="my-3 text-sm font-bold font-medium text-gray-400">
            Buscando registros...
        </h3>
    </div>
</template>

<script>
    import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
    import { ChevronDownIcon } from "@heroicons/vue/24/outline";
    import { useToast } from "vue-toastification";
    import EmptyState from "../../components/layouts/EmptyState.vue";
    import StoreModal from "../../components/config/modals/StoreModal.vue";
    import TictoLoading from "../../components/layouts/TictoLoading.vue";

    export default {
        components: {
            Menu,
            MenuButton,
            MenuItems,
            MenuItem,
            ChevronDownIcon,
            EmptyState,
            StoreModal,
            TictoLoading,
        },

        data() {
            return {
                loading: true,
                legalTerms: [],
                next_page_url: false,
                pagination: {
                    total: 0,
                    per_page: 10,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11,
                },
            }
        },

        setup() {
            const toast = useToast();
            return { toast };
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                let params = {};
                params["page"] = this.pagination.current_page;

                this.loading = true;
                axios
                    .get('/api/legal-terms', {
                        params: params
                    })
                    .then(({data}) => {
                        this.legalTerms = data.data;
                        this.pagination = {
                            ...this.pagination,
                            ...data.links,
                            ...data.meta,
                        };
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            toggle(legalTerm) {
                let questionText = 'Tem certeza que deseja ativar este termo? O termo atualment ativo será automaticamente desativado.';
                let successText = 'Termo ativado com sucesso!';
                if (legalTerm.is_active) {
                    questionText = 'Tem certeza que deseja desativar este termo? O termo mais recente será ativado automaticamente.';
                    successText = 'Termo desativado com sucesso!';
                }
                
                if (confirm(questionText)) {
                    this.loading = true;
                    axios
                        .put(`/api/legal-terms/${legalTerm.id}/toggle`)
                        .then(({ data }) => {
                            if (data ?? null) {
                                this.toast.success(successText);
                                this.fetch();
                            }
                        })
                        .catch(({response: {data: { message }}}) => {
                            this.toast.error(message);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            destroy(legalTerm) {
                let questionText = 'Tem certeza que deseja ativar este termo?';
                if (legalTerm.is_active) {
                    questionText = 'Tem certeza que deseja excluir este termo? Como este é o termo ativo, o termo mais recente será ativado automaticamente.';
                }

                if (confirm(questionText)) {
                    this.loading = true;
                    axios
                        .delete(`/api/legal-terms/${legalTerm.id}`)
                        .then(({ data }) => {
                            if (data ?? null) {
                                this.toast.success("Termo excluído com sucesso!");
                                this.fetch();
                            }
                        })
                        .catch(({response: {data: { message }}}) => {
                            this.toast.error(message);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
        }
    }
</script>
