<template>
    <dl class="my-3 grid grid-cols-2 gap-2 sm:grid-cols-2">
        <highcharts
            v-if="!loading && lineChart"
            class="hc"
            :options="lineChart"
            ref="chart"
        ></highcharts>
        <highcharts
            v-if="!loading && barChart"
            class="hc"
            :options="barChart"
            ref="chart"
        ></highcharts>
    </dl>
</template>

<script>
import LineChart from "../analytics/charts/LineChart.vue";

export default {
    components: { LineChart },
    props: ["dates", "loading"],

    data() {
        return {
            lineChart: false,
            barChart: false,
            selfLoading: true,
            series: [],
        };
    },

    watch: {
        dates() {
            this.fetch();
        },
    },

    methods: {
        chartObject() {
            return {
                tooltip: {
                    formatter: function () {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return (
                                    `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ` +
                                    point.y.toLocaleString("pt-br")
                                );
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: "%d/%m/%Y",
                    shared: true,
                    split: false,
                    enabled: true,
                },
                credits: { enabled: false },
                legend: { enabled: true },
                title: { 
                    align: "left",
                    text: null,
                },
                labels: {
                    format: "{value:%l:%M %p }",
                },
                xAxis: {
                    type: "datetime",
                },
                yAxis: [{
                    title: { text: "Regras" },
                }],
                series: [],
            };
        },

        fetch() {
            this.series = [];
            this.selfLoading = true;
            
            // Gráfico de Linha - Status (Limpo/Bloqueado)
            const passed = {
                type: "spline",
                name: "Limpo",
                lineWidth: 3,
                data: [],
            };
            const blocked = {
                type: "spline",
                name: "Bloqueado",
                lineWidth: 3,
                data: [],
            };

            // Processa dados para o gráfico de linha
            this.dates.day.map((bucket) => {
                const timestamp = new Date(bucket.key_as_string).getTime();
                bucket.passed.buckets.map((statusBucket) => {
                    if(statusBucket.key === true) {
                        passed.data.push([timestamp, statusBucket.total.value]);
                    } else {
                        blocked.data.push([timestamp, statusBucket.total.value]);
                    }
                });
            });

            if (this.dates?.aggregations?.day?.buckets) {
                this.dates.aggregations.day.buckets.forEach(bucket => {
                    const timestamp = new Date(bucket.key_as_string).getTime();
                    bucket.passed.buckets.forEach(statusBucket => {
                        if (statusBucket.key === true) {
                            passed.data.push([timestamp, statusBucket.total.value]);
                        } else {
                            blocked.data.push([timestamp, statusBucket.total.value]);
                        }
                    });
                });
            }

            let lineChart = this.chartObject();
            lineChart.series.push(passed, blocked);
            this.lineChart = lineChart;

            // Gráfico de Barras - Failed Checks
            const failedChecksSeries = [];
            
            this.dates.by_failed_check.map(bucket => {
                const series = {
                    type: "spline",
                    name: bucket.key,
                    lineWidth: 3,
                    data: [],
                };

                bucket.by_day.buckets.map((dayBucket) => {
                    const timestamp = new Date(dayBucket.key_as_string).getTime();
                    series.data.push([timestamp, dayBucket.doc_count]);
                });

                failedChecksSeries.push(series);
            });

            let barChart = this.chartObject();
            barChart.series.push(...failedChecksSeries);
            this.barChart = barChart;

            this.selfLoading = false;
        },
    },
};
</script>
