<template>
    <p
        class="text-md mb-2 font-bold uppercase text-gray-600 dark:text-gray-400"
    >
        Ebooks
    </p>
    <div v-if="loading">
        <ticto-loading></ticto-loading>
    </div>
    <div v-else-if="!ebooks || ebooks.length === 0">
        <p class="flex items-center text-lg text-red-400">
            <XCircleIcon class="mr-1 h-7 w-7 text-red-400" /> O E-book ainda não
            foi anexado.
        </p>
    </div>
    <div v-else>
        <div
            v-for="ebook in ebooks"
            :key="ebook.id"
            class="h-50 mb-4 flex w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
        >
            <div class="flex w-full items-center justify-between gap-4">
                <p
                    v-tooltip="ebook.name"
                    class="flex-1 truncate text-lg dark:text-white"
                >
                    {{ ebook.name }}
                </p>

                <button
                    v-if="ebook.watermark.fileExtension === 'pdf'"
                    @click="showEbook(ebook.id)"
                    class="flex items-center rounded-md bg-blue-600 px-4 py-2 font-medium text-white transition duration-300 ease-in-out hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    :disabled="loadingEbook"
                >
                    <!-- Botão para visualizar PDF -->
                    <template v-if="loadingEbook">
                        <svg class="mr-2 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Carregando...
                    </template>
                    <template v-else>
                        <PhotoIcon class="mr-2 h-5 w-5 text-white" />
                        Visualizar
                    </template>
                </button>
                <button
                    v-else-if="ebook.watermark.fileExtension === 'epub'"
                    @click="downloadEpub(ebook.id)"
                    class="flex items-center rounded-md bg-green-600 px-4 py-2 font-medium text-white transition duration-300 ease-in-out hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                    <!-- Botão para baixar EPUB -->
                    <DownloadIcon class="mr-2 h-5 w-5 text-white" v-if="DownloadIcon" /> <!-- Verifique se o componente está importado corretamente -->
                    Baixar EPUB
                </button>
            </div>
        </div>
    </div>

    <!-- Modal para visualização do eBook -->
    <div v-if="pdfUrl" class="modal-overlay" @click="closePdfModal">
        <div class="modal-content" @click.stop>
            <h1 class="mb-2 text-lg font-bold text-white">Visualização do E-book</h1>
            <div class="flex flex-grow items-stretch">
                <canvas ref="pdfCanvas" class="canvas-custom"></canvas>
            </div>
            <div class="mt-5 flex justify-between space-x-4">
                <button type="button" @click="prevPage" :disabled="currentPage <= 1" class="flex-1 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200">
                    <ChevronLeftIcon class="w-5 h-5 mr-2" />
                    Anterior
                </button>
                <span class="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md">
                    Página {{ currentPage }} de {{ totalPages }}
                </span>
                <button type="button" @click="nextPage" :disabled="currentPage >= totalPages" class="flex-1 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200">
                    Próxima
                    <ChevronRightIcon class="w-5 h-5 ml-2" />
                </button>
                <button type="button" @click="closePdfModal" class="flex-1 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 transition-colors duration-200">
                    <XMarkIcon class="w-5 h-5 mr-2" />
                    Fechar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { PhotoIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import Modal from "../../components/layouts/Modal.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";

export default {
    components: { TictoLoading, XCircleIcon, PhotoIcon, Modal, ChevronLeftIcon, ChevronRightIcon, XMarkIcon },
    props: ["product"],
    data() {
        return {
            loading: true,
            ebooks: [],
            showPdfModal: false,
            pdfUrl: null,
            pdfDocument: null,
            currentPage: 1,
            totalPages: 0,
            loadingEbook: false,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/product/" + this.product.id + "/ebook")
                .then(({ data }) => {
                    console.log("Ebooks data:", data);
                    this.ebooks = data;
                })
                .catch((error) => {
                    console.error("Error fetching ebooks:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        downloadEpub(id) {
            axios
                .get("/api/product/" + this.product.id + `/ebook/${id}/show`)
                .then((response) => {
                    console.log("Resposta completa da API:", response);
                    window.open(response.data.url, "_blank");
                })
                .catch((error) => {
                    console.error("Error downloading epub:", error);
                    alert("Erro ao baixar o EPUB. Por favor, tente novamente.");
                });
        },
        async showEbook(id) {
            this.loadingEbook = true;
            try {
                const response = await axios.get(
                    "/api/product/" + this.product.id + `/ebook/${id}/show`
                );
                console.log("Resposta completa da API:", response);

                if (response.data && response.data.url) {
                    console.log("URL do PDF encontrada na resposta:", response.data.url);
                    this.pdfUrl = response.data.url;
                    this.showPdfModal = true;
                    this.$nextTick(() => {
                        this.loadPdf();
                    });
                } else {
                    console.error("URL do PDF não encontrada na resposta");
                    alert("Erro ao carregar o PDF. Por favor, tente novamente.");
                }
            } catch (error) {
                console.error("Erro ao mostrar ebook:", error);
                alert("Erro ao carregar o PDF. Por favor, tente novamente.");
            } finally {
                this.loadingEbook = false;
            }
        },
        async loadPdf() {
            try {
                const loadingTask = pdfjsLib.getDocument(this.pdfUrl);
                this.pdfDocument = await loadingTask.promise;
                this.totalPages = this.pdfDocument.numPages;
                this.renderPage(1);
            } catch (error) {
                console.error("Erro ao carregar o PDF:", error);
                alert("Erro ao carregar o PDF. Por favor, tente novamente.");
            }
        },
        async renderPage(pageNumber) {
            const page = await this.pdfDocument.getPage(pageNumber);
            const scale = 1.5;
            const viewport = page.getViewport({ scale });

            const canvas = this.$refs.pdfCanvas;
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            await page.render(renderContext);
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.renderPage(this.currentPage);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.renderPage(this.currentPage);
            }
        },
        closePdfModal() {
            this.showPdfModal = false;
            this.pdfUrl = null;
            this.pdfDocument = null;
            this.currentPage = 1;
            this.totalPages = 0;
        },
    },
};
</script>

