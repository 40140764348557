<template>
    <div class="relative">
        <div class="flex justify-end p-4">
            <button
                @click="openDrawer"
                class="flex items-center rounded border border-blue-500 bg-transparent px-4 py-2 text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
            >
                <FunnelIcon class="mr-2 h-5 w-5" aria-hidden="true" />

                Filtros
            </button>
        </div>

        <transition name="slide">
            <div
                v-if="isDrawerOpen"
                class="fixed inset-0 z-50 flex justify-end overflow-y-auto"
            >
                <div
                    class="fixed inset-0 bg-black opacity-50"
                    @click="closeDrawer"
                ></div>
                <div
                    class="relative flex h-full min-h-screen w-80 flex-col bg-gray-800 text-white shadow-xl"
                >
                    <div class="flex h-full flex-col">
                        <div
                            class="flex items-center justify-between border-b p-4"
                        >
                            <h1
                                class="mb-2 border-b border-slate-500 pb-1 text-lg font-bold"
                            >
                                Filtros
                            </h1>

                            <button
                                @click="closeDrawer"
                                class="text-gray-600 hover:text-gray-800"
                            >
                                &times;
                            </button>
                        </div>
                        <div class="flex-1 p-4">
                            <form
                                @submit.prevent="handleFetch"
                                class="flex flex-col space-y-4"
                            >
                                <span class="w-full max-w-md" v-if="dateFilter">
                                    Período
                                    <litepie-datepicker
                                        v-model="filters.date"
                                        :formatter="{
                                            date: 'DD/MM/YYYY',
                                            month: 'MMM',
                                        }"
                                        :shortcuts="false"
                                    />
                                </span>

                                <span class="w-full max-w-md">
                                    Status
                                    <multiple-select
                                        v-if="statusFilter"
                                        :options="statuses"
                                        v-model="filters.status"
                                        placeholder="Status"
                                    />
                                </span>

                                <span class="w-full max-w-md">
                                    Método de Pagamento
                                    <multiple-select
                                        v-if="paymentMethodFilter"
                                        :options="paymentMethods"
                                        v-model="filters.payment_method"
                                        placeholder="Método de Pagto."
                                    />
                                </span>

                                <span class="w-full max-w-md">
                                    ID do Pedido
                                    <input
                                        v-model="filters.orderId"
                                        type="text"
                                        id="text"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        :class="{
                                            'w-5/6': idFilter,
                                        }"
                                    />
                                </span>

                                <span class="w-full max-w-md">
                                    Hash do Pedido
                                    <input
                                        v-model="filters.orderHash"
                                        type="text"
                                        id="text"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        :class="{
                                            'w-5/6': idFilter,
                                        }"
                                    />
                                </span>

                                <span class="w-full max-w-md">
                                    Email do Comprador
                                    <input
                                        v-model="filters.userEmail"
                                        type="text"
                                        id="text"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        :class="{
                                            'w-5/6': idFilter,
                                        }"
                                    />
                                </span>

                                <span class="w-full max-w-md">
                                    Nome do Comprador
                                    <input
                                        v-model="filters.userName"
                                        type="text"
                                        id="text"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        :class="{
                                            'w-5/6': idFilter,
                                        }"
                                    />
                                </span>

                                <span class="w-full max-w-md">
                                    ID Externo de Venda
                                    <input
                                        v-model="filters.externalId"
                                        type="text"
                                        id="text"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        :class="{
                                            'w-5/6': idFilter,
                                        }"
                                    />
                                </span>

                                <div
                                    class="mb-3 flex items-center rounded border border-zinc-300 p-2 px-3 text-sm dark:bg-gray-900"
                                    v-if="creditCardQA"
                                >
                                    <input
                                        type="checkbox"
                                        class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                                        v-model="filters.creditCardQA"
                                        id="creditCardQA"
                                    />
                                    <label
                                        for="creditCardQA"
                                        class="cursor-pointer"
                                        >Cartão de Crédito QA</label
                                    >
                                </div>

                                <div class="flex space-x-2">
                                    <submit-button :loading="loading">
                                        Filtrar
                                    </submit-button>
                                    <button
                                        @click.prevent="clearFilters"
                                        :disabled="loading"
                                        type="button"
                                        :class="{
                                            'cursor-not-allowed': loading,
                                        }"
                                        class="btn-outline-primary px-4 py-1"
                                    >
                                        Limpar filtros
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { FunnelIcon } from "@heroicons/vue/24/outline";
import { nextTick } from "vue";
import MultipleSelect from "../forms/MultipleSelect.vue";
import SubmitButton from "../forms/SubmitButton.vue";
import VueSelect from "../forms/VueSelect.vue";

export default {
    props: {
        loading: { type: Boolean, default: false },
        exportBtn: { type: Boolean, default: false },
        typeFilter: { type: Boolean },
        typeLabel: { type: String, default: "Tipo" },
        dateFilter: { type: Boolean },
        defaultDates: { type: Array, default: () => [] },
        statusFilter: { type: Boolean },
        defaultStatus: { type: Array, default: () => [] },
        statuses: {
            type: Array,
            default: [
                { label: "Abortado", value: "aborted" },
                { label: "Atrasado", value: "delayed" },
                { label: "Autorizado", value: "authorized" },
                { label: "Chargeback", value: "chargeback" },
                { label: "Em Disputa", value: "disputed" },
                { label: "Encerrado", value: "closed" },
                { label: "Expirado", value: "expired" },
                { label: "Incompleta", value: "error" },
                { label: "Pendente", value: "waiting_payment" },
                { label: "Período de Testes", value: "trial" },
                { label: "Processando", value: "processing" },
                { label: "Reclamado", value: "claimed" },
                { label: "Recusado", value: "refused" },
                { label: "Reembolsado", value: "refunded" },
                {
                    label: "Reembolsado Automaticamente",
                    value: "refunded_from_hood",
                },
                {
                    label: "Reembolso Cancelado pelo Produtor",
                    value: "refund_cancelled",
                },
                { label: "Reembolso Solicitado", value: "refund_requested" },
            ],
        },
        paymentMethodFilter: { type: Boolean },
        creditCardQA: { type: Boolean, default: false },
        idFilter: { type: Boolean },
        idFilterPlaceholder: {
            type: String,
            default: "Id da transação",
        },
    },
    components: { MultipleSelect, SubmitButton, VueSelect, FunnelIcon },

    data() {
        return {
            isDrawerOpen: false,
            show: false,
            filters: {
                id: "",
                externalId: "",
                userName: "",
                userEmail: "",
                orderHash: "",
                orderId: "",
                date: this.defaultDates,
                status: this.defaultStatus,
                payment_method: [],
                creditCardQA: false,
            },
            value: [],
            paymentMethods: [
                { value: "pix", label: "Pix" },
                { value: "credit_card", label: "Cartão de Crédito" },
                { value: "bank_slip", label: "Boleto" },
            ],
        };
    },

    computed: {
        filteredPaymentMethods() {
            return this.filters.payment_method.map((paymentMethod) => {
                return {
                    value: paymentMethod,
                    label: this.paymentMethods.find(
                        (s) => s.value === paymentMethod,
                    ).label,
                };
            });
        },
        filteredStatuses() {
            return this.filters.status.map((status) => {
                return {
                    value: status,
                    label: this.statuses.find((s) => s.value === status).label,
                };
            });
        },
    },

    watch: {
        filters: {
            handler(newValue, oldValue) {
                this.emit();
            },
            deep: true,
        },
    },

    async mounted() {
        await nextTick();

        setTimeout(() => {
            this.show = true;
            this.emit();
        }, 100);
    },
    methods: {
        openDrawer() {
            this.isDrawerOpen = true;
        },
        closeDrawer() {
            this.isDrawerOpen = false;
        },
        emit() {
            const params = {};

            if (this.statusFilter) {
                params["filter[status]"] = this.filters.status.join();
            }
            if (this.paymentMethodFilter) {
                params["filter[paymentMethod]"] =
                    this.filters.payment_method.join();
            }
            if (this.dateFilter) {
                params["filter[betweenDates]"] = this.filters.date.join();
            }

            params["filter[externalId]"] = this.filters.externalId;

            params["filter[userName]"] = this.filters.userName;

            params["filter[userEmail]"] = this.filters.userEmail;

            params["filter[orderHash]"] = this.filters.orderHash;

            params["filter[orderId]"] = this.filters.orderId;

            if (this.idFilter) {
                params["filter[id]"] = this.filters.id;
            }
            if (this.typeFilter) {
                params["filter[type]"] = this.filters.types.join();
            }
            if (this.creditCardQA) {
                params["filter[creditCardQA]"] = this.filters.creditCardQA;
            }

            this.$emit("update:modelValue", params);
        },
        handleFetch() {
            this.emit();
            this.isDrawerOpen = false; // Fechar o drawer
            this.$emit("fetch"); // Emitir o evento fetch
        },
        removePaymentMethod(paymentMethod) {
            this.filters.payment_method = this.filters.payment_method.filter(
                (item) => {
                    return item !== paymentMethod.value;
                },
            );
        },
        removeStatus(status) {
            this.filters.status = this.filters.status.filter((item) => {
                return item !== status.value;
            });
        },
        getStatusName(status) {
            switch (status) {
                case "aborted":
                    return "Abortado";
                case "delayed":
                    return "Atrasado";
                case "authorized":
                    return "Autorizado";
                case "chargeback":
                    return "Chargeback";
                case "disputed":
                    return "Em Disputa";
                case "closed":
                    return "Encerrado";
                case "expired":
                    return "Expirado";
                case "error":
                    return "Incompleta";
                case "waiting_payment":
                    return "Pendente";
                case "trial":
                    return "Período de Testes";
                case "processing":
                    return "Processando";
                case "claimed":
                    return "Reclamado";
                case "refused":
                    return "Recusado";
                case "refunded":
                    return "Reembolsado";
                case "refund_requested":
                    return "Reembolso Solicitado";
                default:
                    return "Em Análise";
            }
        },
        clearFilters() {
            this.filters = {
                id: "",
                externalId: "",
                userName: "",
                userEmail: "",
                orderHash: "",
                orderId: "",
                date: [],
                status: [],
                payment_method: [],
                creditCardQA: false,
            };

            this.handleFetch();
        },
    },
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}
.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
}
</style>
