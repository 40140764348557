<template>
    <span
        class="text-sm font-medium mr-2 px-2.5 py-1.5 rounded"
        :class="{
            'bg-green-500 text-white': status == 0,
            'bg-red-500 text-white': status == 1,
            'bg-yellow-500 text-white': status != 0 && status != 1,
        }"
    >
        {{ statusName }}
    </span>
</template>

<script>
export default {
    props: ["status"],

    computed: {
        statusName() {
            switch (this.status) {
                case 0:
                case false:
                    return "Descontado do fornecedor";
                case 1:
                case true:
                    return "Descontado do produtor";
                default:
                    return this.status;
            }
        },
    },
};
</script>
