<template>
    <div
        :class="[
            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
            statusClass,
        ]"
    >
        {{ statusName }}
    </div>
</template>

<script>
export default {
    props: {status: {}, size: {default: 'md'}},

    computed: {
        statusName() {
            switch (this.status) {
                case "requested":
                    return "Solicitado";
                case "claimed":
                    return "Reclamado";
                case "cancelled":
                    return "Cancelado";
                case "failed":
                    return "Falhou";
                default:
                    return "Reembolsado";
            }
        },
        statusClass() {
            switch (this.status) {
                case "requested":
                    return "bg-green-100 text-green-800";
                case "claimed":
                    return "bg-amber-100 text-amber-800";
                case "cancelled":
                    return "bg-red-100 text-red-800";
                case "failed":
                    return "bg-red-100 text-red-800";
                default:
                    return "bg-green-100 text-green-800";
            }
        },
    }
};
</script>
