<template>
    <back-button :route="{ name: 'finnancial.bank-accounts' }" />
    <user-heading v-if="user" :user="user"></user-heading>
    <div class="my-3 flex items-center">
        <h3
            class="align-center mt-0 flex items-center justify-between text-lg font-bold font-medium text-gray-400"
        >
            <span class="mr-2"> Conta #{{ storeBankAccount.id }} </span>

            <update-bank-account
                v-if="storeBankAccount && hasPermission('update.bankAccounts')"
                :bank-account="storeBankAccount"
                @updated="fetchBankAccount()"
            />
        </h3>
    </div>
    <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
            <div
                class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white drop-shadow-sm dark:bg-gray-800"
            >
                <div
                    class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
                >
                    <div
                        class="mb-4 grid grid-cols-4 gap-3"
                        v-if="storeBankAccount"
                    >
                        <info-col title="NOME DO USUÁRIO">
                            <span class="text-gray-400 dark:text-gray-200">
                                {{ storeBankAccount?.user.name }}
                            </span>
                        </info-col>
                        <info-col title="NOME DO FAVORECIDO">
                            <span class="text-gray-400 dark:text-gray-200">
                                {{ storeBankAccount?.holder_name }}
                            </span>
                        </info-col>
                        <info-col title="TIPO DE PESSOA">
                            <span class="text-gray-400 dark:text-gray-200">
                                {{
                                    storeBankAccount.user.type === "company"
                                        ? "Jurídica"
                                        : "Física"
                                }}
                            </span>
                        </info-col>
                        <info-col title="DOCUMENTO">
                            <span class="text-gray-400 dark:text-gray-200">
                                {{
                                    storeBankAccount.user.type === "company"
                                        ? formatCnpj(storeBankAccount.user.cnpj)
                                        : formatCpf(storeBankAccount.user.cpf)
                                }}
                            </span>
                        </info-col>
                        <info-col title="TIPO DE RECEBIMENTO">
                            <span class="text-gray-400 dark:text-gray-200">
                                {{ storeBankAccount.receiver_type }}
                            </span>
                        </info-col>
                        <div
                            class="flex flex-col"
                            v-if="storeBankAccount.receiver_type === 'bank'"
                        >
                            <span
                                class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                >BANCO
                                <font-awesome-icon
                                    icon="fa-solid fa-pencil"
                                    class="mr-1 cursor-pointer dark:text-white"
                                    aria-hidden="true"
                                    @click.prevent="handleClickEditBank()"
                                />
                            </span>
                            <span v-if="this.isLoadingBanks">
                                Carregando...
                            </span>

                            <span v-else-if="this.isEditingBank">
                                <select
                                    id="banks"
                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    v-model="selectedBankId"
                                    @change="handleEditBank()"
                                >
                                    <option
                                        v-for="bank in this.banksOptions"
                                        :value="bank.value"
                                        :key="bank.value"
                                    >
                                        {{ bank.label }}
                                    </option>
                                </select>
                            </span>
                            <span
                                class="text-gray-400 dark:text-gray-200"
                                v-else
                            >
                                {{
                                    storeBankAccount?.bank?.code
                                        ? formatBankCode(
                                              storeBankAccount?.bank?.code,
                                          )
                                        : ""
                                }}
                                -
                                {{ storeBankAccount?.bank?.name ?? "" }}
                            </span>
                        </div>
                        <div
                            class="flex flex-col"
                            v-if="storeBankAccount.receiver_type === 'bank'"
                        >
                            <span
                                class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                >AGÊNCIA</span
                            >
                            <span class="text-gray-400 dark:text-gray-200">
                                {{ storeBankAccount.branch_number }}
                            </span>
                        </div>
                        <div
                            class="flex flex-col"
                            v-if="storeBankAccount.receiver_type === 'bank'"
                        >
                            <span
                                class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                >CONTA</span
                            >
                            <span class="text-gray-400 dark:text-gray-200">
                                {{ storeBankAccount.account_number }} -
                                {{ storeBankAccount.account_check_digit }}
                            </span>
                        </div>
                        <div
                            class="flex flex-col"
                            v-if="storeBankAccount.receiver_type === 'pix'"
                        >
                            <span
                                class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                >CHAVE</span
                            >
                            <span class="text-gray-400 dark:text-gray-200">
                                {{ storeBankAccount.pix_key }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div
                class="h-50 mb-4 flex w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
            >
                <div class="flex w-full" role="status" v-if="loading">
                    <svg
                        aria-hidden="true"
                        class="mx-auto h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
                <ol
                    v-else
                    class="relative w-full border-l border-gray-200 dark:border-gray-700"
                >
                    <li class="mb-10 ml-4" v-for="status in statuses">
                        <div
                            class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white dark:border-gray-900"
                            :class="{
                                'bg-green-500': status.status === 'approved',
                                'bg-red-500': status.status === 'reproved',
                                'bg-yellow-500': status.status === 'pending',
                            }"
                        ></div>
                        <time
                            class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                            >{{ status.created_at }}</time
                        >
                        <h3
                            class="text-lg font-semibold text-gray-900 dark:text-white"
                        >
                            {{ getStatusName(status.status) }}
                        </h3>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-400"
                        >
                            {{ status.user.name }} {{ status.user.email }}
                        </p>
                        <p
                            v-if="
                                status.description &&
                                status.status === 'reproved'
                            "
                            class="mt-1 rounded-lg bg-slate-900 p-4 text-base font-normal text-gray-500 dark:text-gray-400"
                        >
                            {{ status.description }}
                        </p>
                    </li>
                    <li class="ml-4">
                        <div
                            class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700"
                        ></div>
                        <time
                            class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                            >{{ storeBankAccount.created_at }}</time
                        >
                        <h3
                            class="text-lg font-semibold text-gray-900 dark:text-white"
                        >
                            Conta Criada
                        </h3>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-400"
                        >
                            <a
                                @click="approve = !approve"
                                class="inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                            >
                                Aprovar/Reprovar
                                <svg
                                    class="ml-2 h-3 w-3"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </a>
                        </p>
                    </li>
                </ol>
            </div>
            <TransitionRoot
                appear
                :show="approve"
                enter="transition-opacity duration-75"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity duration-150"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
                >
                    <h3
                        class="text-lg font-semibold text-gray-900 dark:text-white"
                    >
                        Aprovar/Reprovar
                    </h3>
                    <label
                        for="countries"
                        class="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
                        >Selecione uma opção</label
                    >
                    <select
                        v-model="form.status"
                        id="countries"
                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    >
                        <option :value="false" selected>Status</option>
                        <option value="approved">Aprovado</option>
                        <option value="reproved">Reprovado</option>
                    </select>

                    <ReasonSelect
                        v-if="form.status === 'reproved'"
                        reasonType="bank_account"
                        @reason-selected="handleReasonSelected"
                    />

                    <button
                        v-if="form.status"
                        @click.prevent="updateStatus"
                        :disabled="loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mx-auto mt-5 inline-flex w-full items-center justify-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                    >
                        <svg
                            v-if="loading"
                            class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Enviar
                    </button>
                </div>
            </TransitionRoot>
        </div>

        <div class="col-span-4">
            <h1
                class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold uppercase text-gray-400"
            >
                <span class="mt-2">DOCUMENTOS BANCÁRIOS</span>
            </h1>

            <div class="flex flex-col gap-4">
                <div
                    class="flex items-center justify-between rounded-md p-4 shadow dark:bg-gray-800"
                    v-for="document in documents"
                    v-bind:key="document.id"
                >
                    <div class="flex items-center gap-4">
                        <div
                            class="flex h-8 w-8 items-center justify-center rounded-md dark:text-white"
                        >
                            <DocumentIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400"
                            />
                        </div>
                        <p class="font-medium dark:text-white">
                            {{ formatDocumentName(document.collection_name) }}
                        </p>
                    </div>
                    <div class="flex items-center gap-2">
                        <div
                            v-if="document.status == 'approved'"
                            class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                        >
                            <div class="mr-10 flex items-center justify-center">
                                <div
                                    class="flex h-10 w-10 items-center justify-center rounded-full bg-green-500"
                                >
                                    <CheckIcon
                                        class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                                    />
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-lg font-bold text-gray-800">
                                        Validado
                                    </h3>
                                    <p class="text-gray-600">
                                        Por: {{ document.reviewed_by.name }}
                                    </p>
                                    <p class="text-gray-600">
                                        Em:
                                        {{ formatDate(document.reviewed_at) }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="document.status == 'pending'"
                            class="mr-10 flex items-center rounded-full border border-yellow-300 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-700"
                        >
                            <ExclamationCircleIcon
                                class="mr-2 h-5 w-5 flex-shrink-0 text-yellow-700"
                            />
                            <span>Pendente Aprovação</span>
                        </div>

                        <div
                            v-if="document.status == 'reproved'"
                            class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                        >
                            <div class="mr-10 flex items-center justify-center">
                                <div
                                    class="flex h-10 w-10 items-center justify-center rounded-full bg-red-500"
                                >
                                    <ExclamationTriangleIcon
                                        class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                                    />
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-lg font-bold text-gray-800">
                                        Reprovado
                                    </h3>
                                    <p class="text-gray-600">
                                        Por: {{ document.reviewed_by.name }}
                                    </p>
                                    <p class="text-gray-600">
                                        Em:
                                        {{ formatDate(document.reviewed_at) }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button
                            @click="showImage(document.temporary_url)"
                            class="flex items-center rounded-md bg-blue-500 px-4 py-2 font-medium text-white"
                        >
                            <PhotoIcon class="mr-2 h-5 w-5 text-gray-400" />
                            Visualizar
                        </button>
                        <button
                            v-if="
                                document.status != 'approved' &&
                                document.status !== null
                            "
                            @click="
                                updateStatusDocument(document.id, 'approved')
                            "
                            class="flex items-center rounded-md bg-green-500 px-4 py-2 font-medium text-white"
                        >
                            <CheckBadgeIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                            />
                            Validar
                        </button>
                        <button
                            v-if="
                                document.status !== 'reproved' &&
                                document.status !== 'approved' &&
                                document.status !== null
                            "
                            @click="
                                updateStatusDocument(document.id, 'reproved')
                            "
                            class="flex items-center rounded-md bg-red-500 px-4 py-2 font-medium text-white"
                        >
                            <ExclamationTriangleIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                            />
                            Reprovar
                        </button>
                    </div>
                </div>
            </div>
            <h1
                class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold uppercase text-gray-400"
            >
                <span class="mt-2">DOCUMENTOS DO USUÁRIO</span>
            </h1>
            <empty-state
                title="Nenhum documento bancário encontrado"
                message="Este usuário ainda não cadastrou nenhuma conta bancária."
                v-if="!loading && documentsUser && documentsUser.length === 0"
            />
            <div class="flex flex-col gap-4">
                <div
                    class="flex items-center justify-between rounded-md p-4 shadow dark:bg-gray-800"
                    v-for="document in documentsUser"
                    v-bind:key="document.id"
                >
                    <div class="flex items-center gap-4">
                        <div
                            class="flex h-8 w-8 items-center justify-center rounded-md dark:text-white"
                        >
                            <DocumentIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400"
                            />
                        </div>
                        <p class="font-medium dark:text-white">
                            {{ formatDocumentName(document.collection_name) }}
                        </p>
                    </div>
                    <div class="flex items-center gap-2">
                        <div
                            v-if="document.status == 'approved'"
                            class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                        >
                            <div class="mr-10 flex items-center justify-center">
                                <div
                                    class="flex h-10 w-10 items-center justify-center rounded-full bg-green-500"
                                >
                                    <CheckIcon
                                        class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                                    />
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-lg font-bold text-gray-800">
                                        Validado
                                    </h3>
                                    <p class="text-gray-600">
                                        Por: {{ document.reviewed_by.name }}
                                    </p>
                                    <p class="text-gray-600">
                                        Em:
                                        {{ formatDate(document.reviewed_at) }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="document.status == 'pending'"
                            class="mr-10 flex items-center rounded-full border border-yellow-300 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-700"
                        >
                            <ExclamationCircleIcon
                                class="mr-2 h-5 w-5 flex-shrink-0 text-yellow-700"
                            />
                            <span>Pendente Aprovação</span>
                        </div>

                        <div
                            v-if="document.status == 'reproved'"
                            class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                        >
                            <div class="mr-10 flex items-center justify-center">
                                <div
                                    class="flex h-10 w-10 items-center justify-center rounded-full bg-red-500"
                                >
                                    <ExclamationTriangleIcon
                                        class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                                    />
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-lg font-bold text-gray-800">
                                        Reprovado
                                    </h3>
                                    <p class="text-gray-600">
                                        Por: {{ document.reviewed_by.name }}
                                    </p>
                                    <p class="text-gray-600">
                                        Em:
                                        {{ formatDate(document.reviewed_at) }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button
                            @click="showImage(document.temporary_url)"
                            class="flex items-center rounded-md bg-blue-500 px-4 py-2 font-medium text-white"
                        >
                            <PhotoIcon class="mr-2 h-5 w-5 text-gray-400" />
                            Download
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal class="4xl" :open="decisionModal" @close="decisionModal = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Validação
        </h1>

        <p class="text-white">
            Você tem certeza que deseja
            {{ decision == "approved" ? "validar" : "reprovar" }} este
            documento?
        </p>

        <div class="mt-3 flex justify-center space-x-3">
            <button
                :disabled="loading"
                type="button"
                @click.prevent="updateStatusFetch(decisionDocumment, decision)"
                :loading="loading"
                class="mb-3 inline-flex w-full max-w-xs justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
            >
                <CheckIcon
                    class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                />
                Sim
            </button>
            <button
                type="button"
                @click.prevent="decisionModal = false"
                class="mb-3 inline-flex w-full max-w-xs justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
            >
                <ExclamationTriangleIcon
                    class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                />
                Não
            </button>
        </div>
    </modal>
    <vue-easy-lightbox
        :visible="vueEasy"
        :imgs="urlDocument"
        :index="0"
        @hide="handleHide"
    />
    <modal
        class="min-h-full"
        size="4xl"
        :open="showDocumentModal"
        @close="showDocumentModal = false"
    >
        <div
            class="flex min-h-full w-full flex-col space-y-3 divide-y divide-slate-500 text-white"
            style="height: 1000px"
        >
            <h1
                class="mb-2 border-b border-slate-500 pb-1 text-lg font-bold text-white"
            >
                Documento
            </h1>

            <div class="col-span-2">
                <div
                    class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white drop-shadow-sm dark:bg-gray-800"
                >
                    <div
                        class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
                    >
                        <div
                            class="mb-4 grid grid-cols-4 gap-3"
                            v-if="storeBankAccount"
                        >
                            <info-col title="NOME DO USUÁRIO">
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{ storeBankAccount?.user.name }}
                                </span>
                            </info-col>
                            <info-col title="NOME DO FAVORECIDO">
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{ storeBankAccount?.holder_name }}
                                </span>
                            </info-col>
                            <info-col title="TIPO DE PESSOA">
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{
                                        storeBankAccount.user.type === "company"
                                            ? "Jurídica"
                                            : "Física"
                                    }}
                                </span>
                            </info-col>
                            <info-col title="DOCUMENTO">
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{
                                        storeBankAccount.user.type === "company"
                                            ? formatCnpj(
                                                  storeBankAccount.user.cnpj,
                                              )
                                            : formatCpf(
                                                  storeBankAccount.user.cpf,
                                              )
                                    }}
                                </span>
                            </info-col>
                            <info-col title="TIPO DE RECEBIMENTO">
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{ storeBankAccount.receiver_type }}
                                </span>
                            </info-col>
                            <div
                                class="flex flex-col"
                                v-if="storeBankAccount.receiver_type === 'bank'"
                            >
                                <span
                                    class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                    >BANCO
                                </span>
                                <span v-if="this.isLoadingBanks">
                                    Carregando...
                                </span>

                                <span
                                    class="text-gray-400 dark:text-gray-200"
                                    v-else
                                >
                                    {{
                                        storeBankAccount?.bank?.code
                                            ? formatBankCode(
                                                  storeBankAccount?.bank?.code,
                                              )
                                            : ""
                                    }}
                                    -
                                    {{ storeBankAccount?.bank?.name ?? "" }}
                                </span>
                            </div>
                            <div
                                class="flex flex-col"
                                v-if="storeBankAccount.receiver_type === 'bank'"
                            >
                                <span
                                    class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                    >AGÊNCIA</span
                                >
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{ storeBankAccount.branch_number }}
                                </span>
                            </div>
                            <div
                                class="flex flex-col"
                                v-if="storeBankAccount.receiver_type === 'bank'"
                            >
                                <span
                                    class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                    >CONTA</span
                                >
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{ storeBankAccount.account_number }} -
                                    {{ storeBankAccount.account_check_digit }}
                                </span>
                            </div>
                            <div
                                class="flex flex-col"
                                v-if="storeBankAccount.receiver_type === 'pix'"
                            >
                                <span
                                    class="text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                                    >CHAVE</span
                                >
                                <span class="text-gray-400 dark:text-gray-200">
                                    {{ storeBankAccount.pix_key }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-grow items-stretch">
                <iframe
                    :src="urlDocument"
                    class="h-full w-full"
                    frameborder="0"
                    allowfullscreen
                ></iframe>
            </div>

            <div class="mt-5 flex justify-center space-x-3">
                <button
                    type="button"
                    @click.prevent="showDocumentModal = false"
                    class="mb-3 inline-flex w-full max-w-xs justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
                >
                    Fechar
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import {
ArrowDownCircleIcon,
ArrowPathIcon,
CheckBadgeIcon,
CheckIcon,
DocumentIcon,
ExclamationCircleIcon,
ExclamationTriangleIcon,
PaperClipIcon,
PencilSquareIcon,
PhotoIcon,
} from "@heroicons/vue/24/outline";
import { mapActions, mapWritableState } from "pinia";
import VueEasyLightbox from "vue-easy-lightbox";
import { useToast } from "vue-toastification";
import UpdateBankAccount from "../../components/finnancial/bankAccount/UpdateBankAccount.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import InfoCol from "../../components/layouts/InfoCol.vue";
import Modal from "../../components/layouts/Modal.vue";
import ProductStatus from "../../components/layouts/ProductStatus.vue";
import ReasonSelect from "../../components/layouts/ReasonSelect.vue";
import BackButton from "../../components/layouts/navigation/BackButton.vue";
import BankMixin from "../../mixins/BankMixin.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";
import { Store } from "../../stores/store.js";
import UserHeading from "../users/UserHeading.vue";

export default {
    components: {
        InfoCol,
        UpdateBankAccount,
        ReasonSelect,
        BackButton,
        VueSelect,
        ProductStatus,
        TransitionRoot,
        ArrowPathIcon,
        UserHeading,
        ArrowDownCircleIcon,
        CheckBadgeIcon,
        CheckIcon,
        DocumentIcon,
        ExclamationCircleIcon,
        ExclamationTriangleIcon,
        PaperClipIcon,
        PencilSquareIcon,
        Modal,
        PhotoIcon,
        VueEasyLightbox,
    },
    mixins: [BankMixin, HelperMixin, PermissionMixin],

    setup() {
        const toast = useToast();
        return { toast };
    },

    data() {
        return {
            approve: false,
            loading: false,
            userStatuses: [],
            statuses: [],
            user: null,
            form: {
                status: false,
                description: "",
            },
            documents: [],
            documentsUser: [],
            banksOptions: [],
            isEditingBank: false,
            isLoadingBanks: false,
            selectedBankId: null,
            decisionModal: false,
            decision: null,
            decisionDocumment: null,
            showDocumentModal: false,
            urlDocument: null,
            vueEasy: false,
        };
    },

    watch: {
        storeBankAccount(account) {
            if (account) {
                this.fetchUserDocuments();
            }

            this.selectedBankId = this.storeBankAccount?.bank?.id ?? "";
        },
    },

    computed: {
        ...mapWritableState(Store, ["storeBankAccount"]),
    },

    mounted() {
        if (!this.storeBankAccount) {
            this.fetchBankAccount();
        } else {
            this.fetchUserDocuments();
        }

        this.fetch();
        this.fetchStatus();
    },

    methods: {
        ...mapActions(Store, ["fetchBankAccount"]),
        async showImage(index) {
            const response = await fetch(index, { method: "GET" });
            const contentType = response.headers.get("Content-Type");

            if (contentType.includes("image")) {
                this.index = index;
                this.urlDocument = index;
                this.vueEasy = true;
            } else {
                this.urlDocument = index;
                this.showDocumentModal = true;
            }
        },
        handleHide() {
            this.vueEasy = false;
        },
        fetchUserStatus() {
            axios
                .get(
                    "/api/user/" +
                        this.this.storeBankAccount.user_id +
                        "/status",
                )
                .then(({ data }) => {
                    this.userStatuses = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.toast.error(error.response.data.message);
                });
        },
        fetchStatus() {
            axios
                .get(
                    "/api/bank-account/" +
                        this.$route.params.account_id +
                        "/status",
                )
                .then(({ data }) => {
                    this.statuses = data;
                    axios
                        .get("/api/user/" + this.storeBankAccount.user_id)
                        .then(({ data }) => {
                            this.user = data;
                        });
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        updateStatusDocument(document, decision) {
            this.decisionModal = true;
            this.decision = decision;
            this.decisionDocumment = document;
        },
        updateStatusFetch(document, decision) {
            const route =
                "/api/bank-account/" +
                this.$route.params.account_id +
                "/status/document";
            axios
                .put(route, { decision: decision })
                .then(({ data }) => {
                    this.toast.success("Salvo com sucesso.");
                    this.documents = [];
                    this.fetchStatus();
                    this.fetch();
                    this.decisionModal = false;
                    this.decision = null;
                    this.decisionDocumment = null;
                })
                .catch((error) => {});
        },
        updateStatus() {
            this.loading = true;
            axios
                .post(
                    "/api/bank-account/" +
                        this.$route.params.account_id +
                        "/status",
                    this.form,
                )
                .then(({ data }) => {
                    this.loading = false;
                    this.approve = false;
                    this.storeProduct = data;
                    this.fetchStatus();
                });
        },
        clearFilters() {
            this.bankAccounts = [];
            this.filters.status = [];
            this.filters.types = [];
            this.next_page_url = false;
            this.fetch();
        },
        fetch() {
            this.loading = true;

            axios
                .get(
                    this.next_page_url ||
                        "/api/bank-account/" +
                            this.$route.params.account_id +
                            "/documents",
                )
                .then(({ data }) => {
                    this.documents = [...this.documents, ...data];
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        openLink(url) {
            this.showDocumentModal = true;
            this.urlDocument = url;
            //window.open(url, "_blank");
        },
        fetchUserDocuments() {
            this.loading = true;
            axios
                .get(
                    "/api/user/" + this.storeBankAccount.user_id + "/documents",
                )
                .then(({ data }) => {
                    this.documentsUser = [...data, ...this.documentsUser];
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },

        fetchBanks() {
            this.isLoadingBanks = true;

            axios
                .get("/api/bank-account/banks")
                .then(({ data }) => {
                    this.banksOptions = data?.map((bank) => {
                        return {
                            value: bank.id,
                            label: `${bank.code} - ${bank.name}`,
                        };
                    });
                    this.isLoadingBanks = false;
                })
                .catch((error) => {
                    this.isLoadingBanks = false;
                    this.toast.error("Erro ao carregar os bancos.");
                });
        },

        handleReasonSelected(reason) {
            this.form.description = reason;
        },

        handleClickEditBank() {
            this.isEditingBank = !this.isEditingBank;

            if (this.isEditingBank) {
                this.fetchBanks();
            }
        },

        handleEditBank() {
            if (confirm("Tem certeza que deseja alterar o banco?")) {
                const payload = {
                    ...this.storeBankAccount,
                    bank_id: this.selectedBankId,
                };

                axios
                    .put(
                        "/api/bank-account/" + this.$route.params.account_id,
                        payload,
                    )
                    .then((response) => {
                        if (response?.data) {
                            this.storeBankAccount.bank = response.data.bank;
                        }

                        this.toast.success("Banco alterado com sucesso.");
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao alterar o banco.");
                    })
                    .finally(() => {
                        this.isEditingBank = false;
                    });
            } else {
                this.isEditingBank = false;
                this.selectedBankId = this.storeBankAccount.bank.id;
            }
        },
    },
};
</script>

<style>
.image-gallery {
    display: flex;
    gap: 10px;
}

.thumbnail {
    width: 100px;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s;
}

.thumbnail:hover {
    transform: scale(1.1);
}
</style>
