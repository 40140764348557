<template>
    <div class="bg-white dark:bg-slate-800 rounded-lg shadow p-4">
        <h3 class="text-sm font-medium text-gray-500 dark:text-gray-400">{{ title }}</h3>
        <div class="mt-2 flex items-baseline">
            <div class="text-2xl font-semibold" 
                :class="{
                    'text-red-600 dark:text-red-500': trend === 'danger',
                    'text-yellow-600 dark:text-yellow-500': trend === 'warning'
                }">
                {{ value }}
            </div>
        </div>
        <p class="mt-1 text-sm text-gray-600 dark:text-gray-300">{{ subtitle }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: [String, Number],
        subtitle: String,
        trend: String
    }
}
</script>

