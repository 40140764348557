<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed inset-0 z-10 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block transform overflow-hidden rounded-lg bg-gray-700 px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
                    >
                        <dialog-title
                            as="h3"
                            class="text-lg font-bold leading-6 text-white"
                        >
                            Alteração #{{ history.id }}
                        </dialog-title>
                        <div class="mt-3">
                            <div
                                class="flex min-w-0 flex-1 items-center"
                            >
                                <div class="flex-shrink-0">
                                    <img
                                        class="h-12 w-12 rounded-full"
                                        :src="
                                            history.user.avatar ||
                                            'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                                history.user.name
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="min-w-0 flex-1 px-4">
                                    <div>
                                        <p
                                            class="flex items-center text-sm text-gray-300"
                                        >
                                            <UserIcon
                                                class="text-gray-300 mr-1.5 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                            <span class="truncate">{{ history.user.name }}</span>
                                        </p>
                                        <p
                                            class="mt-1 flex items-center text-sm text-gray-300"
                                        >
                                            <EnvelopeIcon
                                                class="text-gray-300 mr-1.5 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                            <span class="truncate">{{ history.user.email }}</span>
                                        </p>
                                        <p
                                            class="mt-1 flex items-center text-sm text-gray-300"
                                        >
                                            <ClockIcon
                                                class="text-gray-300 mr-1.5 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                            <span class="truncate">{{ history.created_at }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="my-4 flex-grow border-t border-gray-400"
                            ></div>
                        </div>
                        <div class="mt-4">
                            <div v-if="
                                havePaymentSettingsChanges ||
                                haveRefundProviderFromProducerChanges ||
                                haveChargePlatformFeeUponRefundChanges ||
                                haveRefundLockChanges ||
                                haveCashoutLockChanges ||
                                haveCashoutUnderReviewChanges ||
                                haveInternationalCheckoutChanges
                            ">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-cog"
                                    />
                                    Configurações Financeiras
                                </div>
                                <div v-if="havePaymentSettingsChanges">
                                    <p
                                        class="text-sm text-gray-200 mt-3"
                                    >
                                        Recebimento
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <payment-settings
                                                :status="
                                                    history.properties
                                                        .old
                                                        .allow_payment_in_installments
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <payment-settings
                                                :status="
                                                    history.properties
                                                        .new
                                                        .allow_payment_in_installments
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="haveProviderPaymentSettingsChanges">
                                    <p
                                        class="text-sm text-gray-200 mt-3"
                                    >
                                        Recebimento como fornecedor
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <payment-settings
                                                :status="
                                                    history.properties
                                                        .old
                                                        .allow_payment_in_installments_for_provider
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <payment-settings
                                                :status="
                                                    history.properties
                                                        .new
                                                        .allow_payment_in_installments_for_provider
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="haveRefundProviderFromProducerChanges">
                                    <p
                                        class="text-sm text-gray-200 mt-3"
                                    >
                                        Reembolso de comissão do fornecedor 
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <refund-provider-from-producer
                                                :status="
                                                    history.properties
                                                        .old
                                                        .refund_providers_commission_from_producer
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <refund-provider-from-producer
                                                :status="
                                                    history.properties
                                                        .new
                                                        .refund_providers_commission_from_producer
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="haveChargePlatformFeeUponRefundChanges">
                                    <p
                                        class="text-sm text-gray-200 mt-3"
                                    >
                                        Cobrança da taxa da plataforma no reembolso 
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .old
                                                        .allow_charge_platform_fee_upon_refund
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .new
                                                        .allow_charge_platform_fee_upon_refund
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="haveRefundLockChanges">
                                    <p
                                        class="text-sm text-gray-200 mt-3"
                                    >
                                        Cancelamento de reembolso
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .old
                                                        .is_refund_locked
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .new
                                                        .is_refund_locked
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="haveCashoutLockChanges">
                                    <p
                                        class="text-sm text-gray-200 mt-3"
                                    >
                                        Saque
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    !history.properties
                                                        .old
                                                        .is_cashout_locked
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    !history.properties
                                                        .new
                                                        .is_cashout_locked
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="haveCashoutUnderReviewChanges">
                                    <p
                                        class="text-sm text-gray-200 mt-3"
                                    >
                                        Saque de saldo em análise
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .old
                                                        .allow_cashout_under_review
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .new
                                                        .allow_cashout_under_review
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="haveInternationalCheckoutChanges">
                                    <p class="text-sm text-gray-200 mt-3">
                                        Novo checkout internacional 
                                    </p>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Antigo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .old
                                                        .allow_new_international_checkout
                                                "
                                            />
                                        </div>
                                        <div>
                                            <p
                                                class="text-xs font-medium text-gray-400 my-1"
                                            >
                                                Novo
                                            </p>
                                            <is-allowed
                                                :status="
                                                    history.properties
                                                        .new
                                                        .allow_new_international_checkout
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="mt-5 mb-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="haveAnticipationChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-coins"
                                    />
                                    Antecipação
                                </div>
                                <comparation-log-item
                                    title="Status"
                                    :oldValue="
                                        history.properties.old
                                            .can_anticipate ? 'Ativa' : 'Inativa'
                                    "
                                    :newValue="
                                        history.properties.new
                                            .can_anticipate ? 'Ativa' : 'Inativa'
                                    "
                                />
                                <comparation-log-item
                                    title="Versão"
                                    :oldValue="
                                        history.properties.old
                                            .anticipation_version
                                    "
                                    :newValue="
                                        history.properties.new
                                            .anticipation_version
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa (em %)"
                                    :oldValue="
                                        history.properties.old
                                            .anticipation_fee
                                    "
                                    :newValue="
                                        history.properties.new
                                            .anticipation_fee
                                    "
                                />
                                <comparation-log-item
                                    title="Limite (em %)"
                                    :oldValue="
                                        history.properties.old
                                            .anticipation_limit
                                    "
                                    :newValue="
                                        history.properties.new
                                            .anticipation_limit
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="haveFixedFeeChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-dollar-sign"
                                    />
                                    Cobrança Fixa
                                </div>
                                <comparation-log-item
                                    title="Taxa fixa nacional (R$)"
                                    :oldValue="
                                        history.properties.old
                                            .platform_fixed_fee/100
                                    "
                                    :newValue="
                                        history.properties.new
                                            .platform_fixed_fee/100
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa fixa internacional (R$)"
                                    :oldValue="
                                        history.properties.old
                                            .international_fixed_fee/100
                                    "
                                    :newValue="
                                        history.properties.new
                                            .international_fixed_fee/100
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="haveCreditCardChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-credit-card"
                                    />
                                    Cartão de Crédito e Dois Cartões
                                </div>
                                <comparation-log-item
                                    title="Tempo para saque (em
                                            dias)"
                                    :oldValue="
                                        history.properties.old
                                            .credit_card_cashout_days
                                    "
                                    :newValue="
                                        history.properties.new
                                            .credit_card_cashout_days
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa da plataforma (em
                                            %)"
                                    :oldValue="
                                        history.properties.old
                                            .platform_fee_credit_card
                                    "
                                    :newValue="
                                        history.properties.new
                                            .platform_fee_credit_card
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa de juros por parcela (em
                                        %)"
                                    :oldValue="
                                        history.properties.old
                                            .installment_fee_credit_card
                                    "
                                    :newValue="
                                        history.properties.new
                                            .installment_fee_credit_card
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="haveBankSlipChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-barcode"
                                    />
                                    Boleto
                                </div>
                                <comparation-log-item
                                    title="Tempo para saque (em
                                            dias)"
                                    :oldValue="
                                        history.properties.old
                                            .bank_slip_cashout_days
                                    "
                                    :newValue="
                                        history.properties.new
                                            .bank_slip_cashout_days
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa da plataforma (em
                                            %)"
                                    :oldValue="
                                        history.properties.old
                                            .platform_fee_bank_slip
                                    "
                                    :newValue="
                                        history.properties.new
                                            .platform_fee_bank_slip
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa de juros por parcela (em
                                        %)"
                                    :oldValue="
                                        history.properties.old
                                            .installment_fee_bank_slip
                                    "
                                    :newValue="
                                        history.properties.new
                                            .installment_fee_bank_slip
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="havePixChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-brands fa-pix"
                                    />
                                    Pix
                                </div>
                                <comparation-log-item
                                    title="Tempo para saque (em
                                            dias)"
                                    :oldValue="
                                        history.properties.old
                                            .pix_cashout_days
                                    "
                                    :newValue="
                                        history.properties.new
                                            .pix_cashout_days
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa da plataforma (em
                                            %)"
                                    :oldValue="
                                        history.properties.old
                                            .platform_fee_pix
                                    "
                                    :newValue="
                                        history.properties.new
                                            .platform_fee_pix
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="haveInternationalPaymentChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-globe"
                                    />
                                    Pagamento Internacional
                                </div>
                                <comparation-log-item
                                    title="Tempo para saque (em
                                            dias)"
                                    :oldValue="
                                        history.properties.old
                                            .international_cashout_days
                                    "
                                    :newValue="
                                        history.properties.new
                                            .international_cashout_days
                                    "
                                />
                                <comparation-log-item
                                    title="Taxa da plataforma (em
                                            %)"
                                    :oldValue="
                                        history.properties.old
                                            .international_platform_fee
                                    "
                                    :newValue="
                                        history.properties.new
                                            .international_platform_fee
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="haveReferralChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-star"
                                    />
                                    Programa de Indicações
                                </div>
                                <comparation-log-item
                                    title="Tempo para saque (em
                                            dias)"
                                    :oldValue="
                                        history.properties.old
                                            .referral_cashout_days
                                    "
                                    :newValue="
                                        history.properties.new
                                            .referral_cashout_days
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div v-if="haveCashbackChanges">
                                <div
                                    class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-money-bill-transfer"
                                    />
                                    Cashback
                                </div>
                                <comparation-log-item
                                    title="Tipo de taxa"
                                    :oldValue="
                                        history.properties.old
                                            .cashback_type == 'percentage' ? 'Percentual' : 'Valor Fixo'
                                    "
                                    :newValue="
                                        history.properties.new
                                            .cashback_type == 'percentage' ? 'Percentual' : 'Valor Fixo'
                                    "
                                />
                                <comparation-log-item
                                    title="Valor da taxa"
                                    :oldValue="
                                        history.properties.old
                                            .cashback_fee
                                    "
                                    :newValue="
                                        history.properties.new
                                            .cashback_fee
                                    "
                                />
                                <comparation-log-item
                                    title="Origem"
                                    :oldValue="
                                        history.properties.old
                                            .cashback_origin === 'platform' ? 'Plataforma' : 
                                                history.properties.old
                                                    .cashback_origin === 'interest' ? 'Juros' : 'Todos'
                                    "
                                    :newValue="
                                        history.properties.new
                                            .cashback_origin === 'platform' ? 'Plataforma' : 
                                                history.properties.new
                                                    .cashback_origin === 'interest' ? 'Juros' : 'Todos'
                                    "
                                />
                                <div
                                    class="my-4 flex-grow border-t border-gray-400"
                                ></div>
                            </div>
                            <div>
                                <p
                                    class="text-xs font-medium text-gray-400 my-1"
                                >
                                    Motivo da alteração
                                </p>
                                <input
                                    type="text"
                                    disabled
                                    v-model="history.observation"
                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                />
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                @click="closeModal()"
                                ref="cancelButtonRef"
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {
    EnvelopeIcon,
    GlobeAltIcon,
    CloudArrowUpIcon,
    ClockIcon,
    UserIcon
} from "@heroicons/vue/24/outline";

import HelperMixin from "../../../mixins/HelperMixin.vue";
import ComparationLogItem from "../../../components/layouts/ComparationLogItem.vue";
import PaymentSettings from "../../../components/layouts/PaymentSettings.vue";
import RefundProviderFromProducer from "../../../components/layouts/RefundProviderFromProducer.vue";
import IsAllowed from "../../../components/layouts/IsAllowed.vue";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ComparationLogItem,
        PaymentSettings,
        RefundProviderFromProducer,
        IsAllowed,
        EnvelopeIcon,
        GlobeAltIcon,
        CloudArrowUpIcon,
        ClockIcon,
        UserIcon,
    },
    mixins: [HelperMixin],
    props: {
        history: {
            type: Object,
        },
    },
    computed: {
        havePaymentSettingsChanges() {
            return (
                this.history.properties.old.allow_payment_in_installments !=
                this.history.properties.new.allow_payment_in_installments
            );
        },
        haveProviderPaymentSettingsChanges() {
            return (
                this.history.properties.old.allow_payment_in_installments_for_provider !=
                this.history.properties.new.allow_payment_in_installments_for_provider
            );
        },
        haveRefundProviderFromProducerChanges() {
            return (
                this.history.properties.old.refund_providers_commission_from_producer !=
                this.history.properties.new.refund_providers_commission_from_producer
            );
        },
        haveChargePlatformFeeUponRefundChanges() {
            return (
                this.history.properties.old.allow_charge_platform_fee_upon_refund !=
                this.history.properties.new.allow_charge_platform_fee_upon_refund
            );
        },
        haveRefundLockChanges() {
            return (
                this.history.properties.old.is_refund_locked !=
                this.history.properties.new.is_refund_locked
            );
        },
        haveCashoutLockChanges() {
            return (
                this.history.properties.old.is_cashout_locked !=
                this.history.properties.new.is_cashout_locked
            );
        },
        haveCashoutUnderReviewChanges() {
            return (
                this.history.properties.old.allow_cashout_under_review !=
                this.history.properties.new.allow_cashout_under_review
            );
        },
        haveInternationalCheckoutChanges() {
            return (
                this.history.properties.old.allow_new_international_checkout !=
                this.history.properties.new.allow_new_international_checkout
            );
        },
        haveAnticipationChanges() {
            return (
                this.history.properties.old.can_anticipate !==
                    this.history.properties.new.can_anticipate ||
                this.history.properties.old.anticipation_version !==
                    this.history.properties.new.anticipation_version ||
                this.history.properties.old.anticipation_fee !==
                    this.history.properties.new.anticipation_fee ||
                this.history.properties.old.anticipation_limit !==
                    this.history.properties.new.anticipation_limit
            );
        },
        haveFixedFeeChanges() {
            return (
                parseInt(this.history.properties.old.platform_fixed_fee) !=
                    parseInt(this.history.properties.new.platform_fixed_fee) ||
                parseInt(this.history.properties.old.international_fixed_fee) !=
                    parseInt(this.history.properties.new.international_fixed_fee)
            );
        },
        haveCreditCardChanges() {
            return (
                this.history.properties.old.credit_card_cashout_days !==
                    this.history.properties.new.credit_card_cashout_days ||
                this.history.properties.old.platform_fee_credit_card !==
                    this.history.properties.new.platform_fee_credit_card ||
                this.history.properties.old.installment_fee_credit_card !==
                    this.history.properties.new.installment_fee_credit_card
            );
        },
        haveBankSlipChanges() {
            return (
                this.history.properties.old.bank_slip_cashout_days !==
                    this.history.properties.new.bank_slip_cashout_days ||
                this.history.properties.old.platform_fee_bank_slip !==
                    this.history.properties.new.platform_fee_bank_slip ||
                this.history.properties.old.installment_fee_bank_slip !==
                    this.history.properties.new.installment_fee_bank_slip
            );
        },
        havePixChanges() {
            return (
                this.history.properties.old.pix_cashout_days !==
                    this.history.properties.new.pix_cashout_days ||
                this.history.properties.old.platform_fee_pix !==
                    this.history.properties.new.platform_fee_pix
            );
        },
        haveInternationalPaymentChanges() {
            return (
                this.history.properties.old.international_cashout_days !==
                    this.history.properties.new.international_cashout_days ||
                this.history.properties.old.international_platform_fee !==
                    this.history.properties.new.international_platform_fee
            );
        },
        haveReferralChanges() {
            return (
                this.history.properties.old.referral_cashout_days !==
                    this.history.properties.new.referral_cashout_days
            );
        },
        haveCashbackChanges() {
            return (
                this.history.properties.old.cashback_type !==
                    this.history.properties.new.cashback_type ||
                this.history.properties.old.cashback_fee !==
                    this.history.properties.new.cashback_fee ||
                this.history.properties.old.cashback_origin !==
                    this.history.properties.new.cashback_origin
            );
        },
    },

    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
    },
};
</script>
