<template>
    <div class="w-full">
        <h3 class="text-lg font-medium text-gray-400 mb-4">Evolução das Comissões</h3>
        <highcharts class="hc" :options="chartObject" ref="chart"></highcharts>
    </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        filters: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chartObject: {
                tooltip: {
                    formatter: function() {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ` + 
                                    point.y.toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                    });
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: true
                },
                title: {
                    align: 'left',
                    text: null
                },
                xAxis: {
                    type: 'datetime',
                },
                yAxis: {
                    title: null,
                },
                series: []
            }
        };
    },

    watch: {
        filters: {
            handler() {
                this.fetch();
            },
            deep: true
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            axios.get(`/api/user/${this.user.id}/commission/chart`, {
                params: this.filters,
            }).then(({ data }) => {
                this.chartObject.series = [{
                    name: 'Comissões',
                    lineWidth: 3,
                    data: data.map(item => [
                        item.unix_date,
                        item.amount / 100
                    ])
                }];
            });
        }
    }
};
</script>
