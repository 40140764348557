<template>
    <back-button :route="{ name: 'users' }" />
    <div v-if="user">
        <user-heading :user="user">
            <div class="flex items-center">
                <span
                    v-if="userPendingRecords"
                    class="ml-2 rounded bg-red-100 px-2.5 py-1.5 text-sm font-medium text-white dark:bg-red-800"
                >
                    {{ userPendingRecords }}
                </span>
                <user-dropdown />
            </div>
        </user-heading>
        <balance-cards :user="user" />
        <nav-tabs :tabs="tabs" class="mb-2" />
        <router-view :user="user"></router-view>
    </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { mapActions, mapWritableState } from "pinia";
import Card from "../../components/layouts/Card.vue";
import NavTabs from "../../components/layouts/NavTabs.vue";
import BackButton from "../../components/layouts/navigation/BackButton.vue";
import BalanceCards from "../../components/user/BalanceCards.vue";
import { Store } from "../../stores/store";
import UserDropdown from "./UserDropdown.vue";
import UserHeading from "./UserHeading.vue";
import UserTrackings from "./audit/UserTrackings.vue";
import Commissions from "./commissions/Commissions.vue";
import Statement from "./statement/Statement.vue";

export default {
    components: {
        BackButton,
        NavTabs,
        BalanceCards,
        Commissions,
        Statement,
        Card,
        MenuHeadless: Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        UserDropdown,
        UserHeading,
        UserTrackings,
    },
    data() {
        return {
            activeTab: "details",
            tabs: [
                {
                    name: "details",
                    label: "Detalhes",
                    route: "user.settings",
                    params: { user_id: this.$route.params.user_id },
                },
                {
                    name: "relations",
                    label: "Relacionamentos",
                    route: "user.relations",
                    params: { user_id: this.$route.params.user_id },
                },
                {
                    name: "products",
                    label: "Produtos",
                    route: "user.products",
                    params: { user_id: this.$route.params.user_id },
                },
                {
                    name: "referrals",
                    label: "Indicação",
                    route: "user.referrals",
                    params: { user_id: this.$route.params.user_id },
                },
                {
                    name: "financial",
                    label: "Financeiro",
                    route: "user.financial",
                    params: { user_id: this.$route.params.user_id },
                },
                {
                    name: "statement",
                    label: "Extrato",
                    route: "user.statement",
                    params: { user_id: this.$route.params.user_id },
                },
                // {
                //     name: "log",
                //     label: "Log",
                //     route: "user.statement-log",
                //     params: { user_id: this.$route.params.user_id },
                // },
                 {
                     name: "anticipation-histories",
                     label: "Antecipações",
                     route: "user.anticipation-histories",
                     params: { user_id: this.$route.params.user_id },
                 },
                {
                    name: "commissions",
                    label: "Comissões",
                    route: "user.commissions",
                    params: { user_id: this.$route.params.user_id },
                },
                {
                    name: "permissions",
                    label: "Permissões",
                    route: "user.permissions",
                    params: { user_id: this.$route.params.user_id },
                },
                {
                    name: "plates",
                    label: "Placas",
                    route: "user.plates",
                    params: { user_id: this.$route.params.user_id },
                },
            ],
            loading: false,
            balance: {
                available_balance: 0,
                pending_balance: 0,
                anticipation_balance: 0,
            },
            payables: false,
        };
    },
    computed: {
        ...mapWritableState(Store, ["user", "userPendingRecords"]),
    },

    mounted() {
        if (!this.user) {
            this.fetchUser().then(() => {
                this.addPermissionNav();
            });
        } else {
            this.addPermissionNav();
        }
    },

    methods: {
        addPermissionNav() {
            if (this.user) {
                this.tabs.push({
                    name: "audit",
                    label: "Auditoria",
                    route: "user.trackings",
                    params: { user_id: this.$route.params.user_id },
                });
            }
        },
        ...mapActions(Store, ["fetchUser"]),
        updateBalance() {
            this.balance_updating = true;
            axios
                .post("/api/user/" + this.$route.params.user_id + "/balance")
                .then(({ data }) => {
                    this.balance_updating = false;
                    this.pending_records = data.pending_records;
                })
                .catch(({ response }) => {
                    this.balance_updating = false;
                });
        },
        formatCurrency(value) {
            return (value / 100).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
    },
};
</script>
