<template>
    <div class="flex flex-col">
        <h3 class="mb-2 text-lg font-bold text-gray-400">
            Evolução do Score
        </h3>
        <line-chart :series="series" :loading="loading" />
    </div>
</template>

<script>
import LineChart from "../../analytics/charts/LineChart.vue";

export default {
    components: { LineChart },
    props: ["user"],
    data() {
        return {
            loading: true,
            series: [],
        };
    },
    mounted() {
        this.fetchScoreHistory();
    },
    methods: {
        async fetchScoreHistory() {
            this.loading = true;
            try {
                const { data } = await axios.get(`/api/user/${this.user.id}/score/history`);
                console.log('Dados recebidos:', data); // Debug dos dados da API
                
                const scoreSeries = {
                    name: "Score",
                    data: data.scores.map((score) => [
                        new Date(score.created_at).getTime(),
                        Number(score.amount)
                    ]),
                    lineWidth: 3,
                };
                
                console.log('Series formatada:', scoreSeries); // Debug da série formatada
                this.series = [scoreSeries];
                
            } catch (error) {
                console.error("Erro ao buscar histórico de scores:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        // Adicionar watch para debug
        series: {
            handler(newVal) {
                console.log('Series mudou:', newVal);
            },
            deep: true
        }
    }
};
</script>
