<template>
    <div class="flex flex-col dark:text-white">
        <div class="my-5 flex flex-col">
            <form
                class="flex content-center items-center"
                @submit.prevent="
                    users = [];
                    next_page_url = false;
                    fetch();
                "
            >
                <div class="grow">
                    <input
                        v-model="filters.text"
                        type="text"
                        id="text"
                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        placeholder="Filtre pelo id ou nome"
                    />
                </div>
                <div class="flex flex-none">
                    <button
                        @click.prevent="clearFilters"
                        :disabled="false"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mx-2 mr-2 inline-flex items-center rounded-md border-2 border-indigo-500 bg-transparent px-4 py-2 text-sm font-semibold leading-6 text-indigo-500 shadow transition duration-150 ease-in-out hover:bg-indigo-500 hover:text-white"
                    >
                        Limpar filtros
                    </button>
                    <button
                        @click.prevent="
                            users = [];
                            next_page_url = false;
                            fetch();
                        "
                        :disabled="loading"
                        type="submit"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mx-auto inline-flex items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                    >
                        <svg
                            v-if="loading"
                            class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Filtrar
                    </button>
                </div>
            </form>
            <div class="my-4 grid gap-2 md:grid-cols-6">
                <multiple-select
                    placeholder="Status"
                    :options="[
                        {
                            value: 'onboarding-waiting',
                            label: 'Aguardando Onboarding',
                        },
                        {
                            value: 'onboarding-pending',
                            label: 'Onboarding Pendente',
                        },
                        {
                            value: 'onboarding-approved',
                            label: 'Onboarding Aprovado',
                        },
                        {
                            value: 'onboarding-reproved',
                            label: 'Onboarding Reprovado',
                        },
                        {
                            value: 'onboarding-pending-correction',
                            label: 'Onboarding Pendente de Correção',
                        },
                        { value: 'pending', label: 'Pendentes de Análise' },
                        {
                            value: 'pending-correction',
                            label: 'Pendentes de Correção',
                        },
                        { value: 'approved', label: 'Aprovado' },
                        { value: 'reproved', label: 'Reprovado' },
                    ]"
                    v-model="filters.status"
                />
                <multiple-select
                    placeholder="Pessoa"
                    :options="[
                        { value: 'person', label: 'Física' },
                        { value: 'company', label: 'Jurídica' },
                    ]"
                    v-model="filters.types"
                />
                <multiple-select
                    placeholder="Emancipado"
                    :options="[
                        {value: 1, label: 'Sim'},
                        {value: 0, label: 'Não'}
                    ]"
                    v-model="filters.emancipated"
                />
            </div>
            <div class="flex">
                <span
                    v-if="filters.status.length > 0 || filters.types.length > 0"
                >
                    Filtrando por:
                    <div v-if="filters.status.length > 0" class="mt-3">
                        Status:
                        <span
                            v-for="status in filteredStatuses"
                            @click="removeStatus(status)"
                            class="mr-1 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0 hover:cursor-pointer"
                        >
                            {{ status.label }}
                            <font-awesome-icon
                                icon="fa-solid fa-times"
                                class="h-3 w-3 text-gray-200 dark:text-gray-200"
                                aria-hidden="true"
                            />
                        </span>
                    </div>
                    <div v-if="filters.types.length > 0" class="mt-3">
                        Pessoa:
                        <span
                            v-for="type in filteredTypes"
                            @click="removeType(type)"
                            class="mr-1 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0 hover:cursor-pointer"
                        >
                            {{ type.label }}
                            <font-awesome-icon
                                icon="fa-solid fa-times"
                                class="h-3 w-3 text-gray-200 dark:text-gray-200"
                                aria-hidden="true"
                            />
                        </span>
                    </div>
                    <div v-if="filters.emancipated.length > 0" class="mt-3">
                        Emancipado:
                        <span
                            v-for="value in filteredEmancipated"
                            @click="removeEmancipated(value)"
                            class="mr-1 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0 hover:cursor-pointer"
                        >
                            {{ value.label }}
                            <font-awesome-icon
                                icon="fa-solid fa-times"
                                class="h-3 w-3 text-gray-200 dark:text-gray-200"
                                aria-hidden="true"
                            />
                        </span>
                    </div>
                </span>
            </div>
        </div>
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
                <div
                    class="not-prose relative overflow-hidden overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25"
                >
                    <table class="w-full table-auto border-collapse text-sm">
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium"
                                ></th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium"
                                >
                                    Nome
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium"
                                >
                                    E-mail
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium"
                                >
                                    Telefone
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium"
                                >
                                    Pessoa
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-center text-sm font-medium"
                                >
                                    Usuário Migrado?
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-center text-sm font-medium"
                                >
                                    Email Verificado?
                                </th>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium"
                                >
                                    Último Status
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white dark:bg-slate-800">
                            <tr v-if="loading" v-for="n in 5">
                                <td
                                    v-for="n in 8"
                                    class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <div
                                        class="h-2 w-full animate-pulse rounded bg-gray-500"
                                    ></div>
                                </td>
                            </tr>
                            <tr v-if="!loading" v-for="user in users">
                                <td
                                    class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <div
                                        @click.prevent="manage(user)"
                                        class="text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                                    >
                                        #{{ user.id }}
                                    </div>
                                </td>
                                <td
                                    class="items-center justify-center border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ user.name }}
                                </td>
                                <td
                                    class="items-center justify-center border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ user.email }}
                                </td>
                                <td
                                    class="items-center justify-center border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ user.formatted_phone }}
                                </td>
                                <td
                                    class="items-center justify-center border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ user.formatted_type }}
                                </td>
                                <td
                                    class="items-center border-b border-slate-100 p-3 text-center text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <span v-if="user.legacy_id">
                                        <font-awesome-icon
                                            :icon="['fas', 'check']"
                                            style="color: #11ff00"
                                    /></span>
                                    <span v-else>
                                        <font-awesome-icon
                                            :icon="['fas', 'xmark']"
                                            style="color: #ff0000"
                                    /></span>
                                </td>
                                <td
                                    class="items-center border-b border-slate-100 p-3 text-center text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <span v-if="user.email_verified_at">
                                        <font-awesome-icon
                                            :icon="['fas', 'check']"
                                            style="color: #11ff00"
                                    /></span>
                                    <span v-else>
                                        <font-awesome-icon
                                            :icon="['fas', 'xmark']"
                                            style="color: #ff0000"
                                    /></span>
                                </td>
                                <td
                                    class="items-center justify-center border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <span v-if="user.status" class="flex">
                                        <div
                                            class="mr-2 h-4 w-4 rounded-full"
                                            :class="{
                                                'bg-green-500': [
                                                    'approved',
                                                    'onboarding-approved',
                                                ].includes(user.status),
                                                'bg-red-500': [
                                                    'reproved',
                                                    'onboarding-reproved',
                                                ].includes(user.status),
                                                'bg-yellow-500': [
                                                    'pending',
                                                    'pending-correction',
                                                    'onboarding-pending-correction',
                                                    'onboarding-waiting',
                                                ].includes(user.status),
                                            }"
                                        ></div>
                                        {{ getStatusName(user.status) }}
                                    </span>
                                    <span v-else class="flex">
                                        <div
                                            class="mr-2 h-4 w-4 rounded-full bg-yellow-500"
                                        ></div>
                                        Pendente
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="my-5 flex w-full">
                        <div class="mx-auto flex flex-col items-center">
                            <span class="mb-1">
                                Exibindo {{ pagination.from }} a
                                {{ pagination.to }} registros de
                                {{ pagination.total }}
                            </span>
                            <pagination
                                :pagination="pagination"
                                :offset="1"
                                @paginate="fetch"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapWritableState } from "pinia";
import { useToast } from "vue-toastification";
import MultipleSelect from "../../components/forms/MultipleSelect.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import { Store } from "../../stores/store.js";

export default {
    components: { MultipleSelect, VueSelect, Pagination },
    mixins: [HelperMixin],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            next_page_url: false,
            users: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                status: [],
                types: [],
                emancipated: [],
            },
        };
    },

    computed: {
        ...mapWritableState(Store, ["storeuser"]),
        filteredStatuses() {
            return this.filters.status.map((status) => {
                return {
                    value: status,
                    label: this.getStatusName(status),
                };
            });
        },
        filteredTypes() {
            return this.filters.types.map((type) => {
                return {
                    value: type,
                    label: this.getPersonTypeName(type),
                };
            });
        },
        filteredEmancipated() {
            return this.filters.emancipated.map((bool) => {
                return {
                    value: bool,
                    label: this.getBooleanName(bool),
                };
            });
        },
    },

    mounted() {
        this.storeProduct = false;
        let complianceFilters = localStorage.getItem("compliance_filters");
        if (complianceFilters) {
            this.filters = { ...this.filters, ...JSON.parse(complianceFilters) };
        }
        this.fetch();
    },

    methods: {
        removeEmancipated(type) {
            this.filters.emancipated = this.filters.emancipated.filter((item) => {
                return item !== type.value;
            });
        },
        removeType(type) {
            this.filters.types = this.filters.types.filter((item) => {
                return item !== type.value;
            });
        },
        removeStatus(status) {
            this.filters.status = this.filters.status.filter((item) => {
                return item !== status.value;
            });
        },
        clearFilters() {
            this.users = [];
            this.filters.status = [];
            this.filters.types = [];
            this.filters.text = "";
            this.filters.emancipated = [];
            this.next_page_url = false;
            this.fetch();
        },
        manage(user) {
            this.storeuser = user;
            this.$router.push({
                name: "compliance.details",
                params: { user_id: user.id },
            });
        },
        fetchFilters() {
            return axios
                .get("/api/filter", {
                    params: {
                        filter_key: "compliance",
                    },
                })
                .then(({ data }) => {
                    const filters = data.filters;
                    if (filters.hasOwnProperty("status")) {
                        this.filters.status = filters.status;
                    }
                    if (filters.hasOwnProperty("current_page")) {
                        this.pagination.current_page = filters.current_page;
                    }
                });
        },
        async fetch() {
            this.loading = true;

            localStorage.setItem(
                "compliance_filters",
                JSON.stringify(this.filters),
            );

            axios
                .get("/api/compliance", {
                    params: {
                        status: this.filters.status,
                        types: this.filters.types,
                        text: this.filters.text,
                        emancipated: this.filters.emancipated,
                        page: this.pagination.current_page,
                    },
                })
                .then(({ data }) => {
                    this.users = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    // this.$toast.error(error.response.data.message);
                });
        },
    },
};
</script>
